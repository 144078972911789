const { fragment: ResponsiveImageFragment } = require("../ResponsiveImage/ResponsiveImage.fragment")
const { fragment: LinkFragment } = require("../Link/Link.fragment")

const fragment = `
    sys {
        id
    }
    backgroundImage {
        ${ResponsiveImageFragment}
    }
    foregroundImage {
        ${ResponsiveImageFragment}
    }
    link {
        ${LinkFragment}
    }
    variant
`
module.exports = { fragment }
