export const defaultStyle = {
    faqWrapper: "w-full pb-[80px]",
    faqTitle: "specialHeading-2 text-center mb-[80px]",
    faqContainer: "relative border-t-[2px] last:border-b-[2px] border-gold-400 mx-[16px] md:mx-[32px] z-[9] ",
    faqHeader:
        "cursor-pointer w-full min-h-[64px] flex gap-[18px] md:gap-[54px] select-none flex-row items-center justify-between text-left",
    faqHeaderOpen:
        "w-full cursor-pointer  flex gap-[18px] md:gap-[54px] select-none flex-row items-center justify-between text-left transition-all duration-200 ease-out z-1",
    expandedContainer:
        " relative cursor-pointer overflow-hidden transition-height duration-200 ease-in-out z-[-1] border-beige-500",
    expandedTextContainer: "py-[20px] border-gold-400 border-t-[2px]",
    faqQuestion: "text-left text-white heading-2 py-[32px]",
    faqAnswer: "text-white body-1 py-[16px] [&_a]:underline [&_a]:decoration-gold-400",
    linkWrapper: "pt-[80px] flex justify-center gap-[16px] md:gap-[32px]",
    bottomLinks: "btn-primary",
}
