import React, { useState } from "react"
import PropTypes from "prop-types"
import FaqItem from "../FaqSection/FaqItem"
import * as styles from "./FaqSection-tw-styles"
import Link from "../UI/Link/Link"

const FaqSection = props => {
    const { faqItemsCollection, title, linkItemsCollection } = props
    const style = styles.defaultStyle
    const items = faqItemsCollection.items
    const linkItems = linkItemsCollection?.items || []
    const [openedIndex, setOpenedIndex] = useState(-1)
    if (items.length === 0) {
        return null
    }

    return (
        <div className={style?.faqWrapper}>
            <h2 className={style?.faqTitle}>{title}</h2>
            <div>
                {items.map((data, index) => (
                    <FaqItem
                        key={data.sys.id}
                        setOpenedIndex={setOpenedIndex}
                        isOpen={openedIndex === index}
                        index={index}
                        {...data}
                    />
                ))}
            </div>
            <div className={style?.linkWrapper}>
                {linkItems.map(data => (
                    <Link className={style?.bottomLinks} key={data.sys.id} {...data} />
                ))}
            </div>
        </div>
    )
}

FaqSection.propTypes = {
    title: PropTypes.string,
    faqItemsCollection: PropTypes.object,
    linkItemsCollection: PropTypes.object,
}

export default FaqSection
