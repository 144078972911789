import React from "react"
import PropTypes from "prop-types"
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage"
import * as styles from "./CocoaProfileCard-tw-styles"
import { gaEventClasses } from "../../../constants/gtm.constants"

function CocoaProfileCard(props) {
    const {
        title,
        backgroundImage,
        foregroundImage,
        onCardClick,
        isCardActive = false,
        variant = "cocoaLifeSection",
        className,
    } = props
    const style = styles[variant]

    return (
        <div
            className={`${isCardActive ? style.wrapperActive : style.wrapper} ${className ? className : ""} ${
                gaEventClasses?.image_click || ""
            }`}
            data-action-detail={`Cocoa profile card ${title}`}
            onClick={onCardClick}
        >
            <h3 className={style.title}>{title}</h3>
            <div className={style.images}>
                <div className={style.backgroundImageWrapper}>
                    <ResponsiveImage contentfulImagesComponent={backgroundImage} className={style.backgroundImage} />
                </div>
                <div className={style.foregroundImageWrapper}>
                    <ResponsiveImage contentfulImagesComponent={foregroundImage} className={style.foregroundImage} />
                </div>
            </div>
        </div>
    )
}

CocoaProfileCard.propTypes = {
    title: PropTypes.string,
    profileId: PropTypes.string,
    backgroundImage: PropTypes.object,
    foregroundImage: PropTypes.object,
    onCardClick: PropTypes.func,
    isCardActive: PropTypes.bool,
    variant: PropTypes.oneOf(["cocoaLifeSection", "cocoaLifeCarousel"]),
    className: PropTypes.string,
}

export default CocoaProfileCard
