import { useEffect, useState } from "react"
import algoliaSearchInstance from "../service/AlgoliaService"

const useSearchResults = ({ language, hitsPerPage }) => {
    const [results, setResults] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    let query
    if (typeof window !== "undefined") {
        const urlParams = new URLSearchParams(window.location.search)
        query = urlParams.get("q")
    }

    useEffect(() => {
        if (typeof window === "undefined") return
        if (!query) return

        const fetchResults = async () => {
            setLoading(true)
            setError(null)
            try {
                const searchOptions = {
                    hitsPerPage,
                }
                const data = await algoliaSearchInstance.getSearchResults("search", language, query, searchOptions)
                setResults(data)
            } catch (err) {
                setError(err?.message || "An error occurred while fetching search results")
            } finally {
                setLoading(false)
            }
        }

        fetchResults()
    }, [language, query, hitsPerPage])

    return {
        searchedTerm: query || null,
        results,
        loading,
        error,
    }
}

export default useSearchResults
