import React from "react"
import PropTypes from "prop-types"
import { defaultStyle } from "./ImageWithText-tw-styles"
import ResponsiveImage from "../UI/ResponsiveImage/ResponsiveImage"
import Typography from "../UI/Typography/Typography"
import Link from "../UI/Link/Link"

const ImageWithText = props => {
    const { style, title, subtitle, contentHead, contentBody, image, cta } = props
    return (
        <div className={defaultStyle.wrapper}>
            {(!!title || !!subtitle) && (
                <div className={defaultStyle.titleWrapper}>
                    {!!subtitle && <h3 className={defaultStyle.heading3}>{subtitle}</h3>}
                    {!!title && <h2 className={defaultStyle.heading2}>{title}</h2>}
                </div>
            )}
            <div className={style.gridWrapper}>
                <div className={style.imageWrapper}>
                    <ResponsiveImage className={defaultStyle.image} contentfulImagesComponent={image} />
                </div>
                {(!!contentHead || !!contentBody) && (
                    <div className={style.textWrapper}>
                        {!!contentHead && (
                            <Typography
                                className={`${defaultStyle.contentHead} ${style.typogaphy}`}
                                content={contentHead}
                            />
                        )}
                        {!!contentBody && (
                            <Typography
                                className={`${defaultStyle.contentBody} ${style.typogaphy}`}
                                content={contentBody}
                            />
                        )}
                    </div>
                )}
            </div>
            {Object.keys(cta).length !== 0 && (
                <div className={defaultStyle.linkWrapper}>
                    <Link {...cta} className={defaultStyle.link} />
                </div>
            )}
        </div>
    )
}

export default ImageWithText

ImageWithText.propTypes = {
    style: PropTypes.object,
    image: PropTypes.object,
    cta: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    contentHead: PropTypes.string,
    contentBody: PropTypes.string,
}
