export const defaultStyle = {
    blockContent: "flex flex-col gap-[40px] lg:gap-[86px]",
    blockTitle: "specialHeading-4 text-white text-center max-w-[1144px] mx-auto xxl:max-w-[1647px]",
    blockDescription: {
        richTextWrapper: "text-white text-center max-w-[1144px] mx-auto xxl:max-w-[1647px]",
        code: "hidden",
        link: "",
        paragraph: "heading-4 [&_a]:hover:underline",
        heading1: "hidden",
        heading2: "hidden",
        heading3: "hidden",
        heading4: "hidden",
        heading5: "hidden",
        heading6: "hidden",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "hidden",
        list: "hidden",
        title: "hidden",
        mainLink: "hidden",
    },
    videoWrapper:
        "relative aspect-video w-full max-w-[1144px] mx-auto xxl:max-w-[unset] rounded-[12px] lg:rounded-[14px] border-2 border-gold-400 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]",
}
