import React from "react"
import PropTypes from "prop-types"
import HomeHeroSlider from "./HomeHeroSlider/HomeHeroSlider"
import ImageHeroBanner from "../ImageHeroBanner/ImageHeroBanner"

export const SLIDER_VARIANTS = {
    HOME_HERO_SLIDER: "HOME_HERO_SLIDER",
}

const HeroSlider = props => {
    const renderHeroSlider = sliderProps => {
        switch (sliderProps?.variant) {
            case SLIDER_VARIANTS.HOME_HERO_SLIDER:
                return <HomeHeroSlider {...sliderProps} />
            default:
                return <div>Unhandled variant</div>
        }
    }

    return props.variant ? renderHeroSlider(props) : null
}

HeroSlider.propTypes = {
    style: PropTypes.object,
    variant: PropTypes.string,
    slidesCollection: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape(ImageHeroBanner.propTypes)),
    }),
}

export default HeroSlider
