import React from "react"
import PropTypes from "prop-types"
import Link from "../UI/Link/Link"
import LinkCard from "../UI/LinkCard/LinkCard"
import Slider from "react-slick"
import { PrevArrow, NextArrow } from "../UI/Carousel/Arrows"

const InstagramFeedSection = props => {
    const { style, title, postsCollection, cta } = props
    const { items } = postsCollection
    const settings = {
        className: "instagram-posts-slider instagram-posts-slick",
        prevArrow: <PrevArrow buttonStyles={style.arrowLeft} />,
        nextArrow: <NextArrow buttonStyles={style.arrowRight} />,
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        centerMode: false,
        variableWidth: true,
        infinite: true,
        initialSlide: 1,
        responsive: [
            {
                breakpoint: 2559,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true,
                    centerMode: false,
                    variableWidth: true,
                    infinite: true,
                },
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    variableWidth: true,
                    infinite: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    variableWidth: true,
                    infinite: true,
                },
            },
        ],
    }

    return (
        <div className={style?.wrapper}>
            <div className={style?.container}>
                {title && <h2 className={style?.title}>{title}</h2>}
                {Array.isArray(items) && items.length > 0 && (
                    <div className={style?.postsWrapper}>
                        <Slider {...settings}>
                            {items.map(post => (
                                <LinkCard {...post} key={`soclia_post_${post.sys.id}`} />
                            ))}
                        </Slider>
                    </div>
                )}
                {!!cta && <Link {...cta} className={style?.cta} />}
            </div>
        </div>
    )
}

InstagramFeedSection.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    postsCollection: PropTypes.shape({
        items: PropTypes.array,
    }),
    cta: PropTypes.object,
}

export default InstagramFeedSection
