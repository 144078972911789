const APP_ID = process.env.GATSBY_ALGOLIA_APP_ID
const SEARCH_KEY = process.env.GATSBY_ALGOLIA_SEARCH_KEY
const API_KEY = process.env.GATSBY_ALGOLIA_API_KEY

const INDEX_RECIPE_NL_BE = process.env.GATSBY_ALGOLIA_RECIPES_BE_NL_PRIMARY_INDEX_NAME || "cotedor_com_be-nl_recipe_dev"
const INDEX_RECIPE_FR_BE = process.env.GATSBY_ALGOLIA_RECIPES_BE_FR_PRIMARY_INDEX_NAME || "cotedor_com_be-fr_recipe_dev"
const INDEX_RECIPE_NL_NL = process.env.GATSBY_ALGOLIA_RECIPES_NL_PRIMARY_INDEX_NAME || "cotedor_com_nl_recipe_dev"

const INDEX_SEARCH_NL_BE =
    process.env.GATSBY_ALGOLIA_INDEX_BE_NL_CONTENTFUL_PAGES || "cotedor_com_be-nl_contentful_pages"
const INDEX_SEARCH_FR_BE =
    process.env.GATSBY_ALGOLIA_INDEX_BE_FR_CONTENTFUL_PAGES || "cotedor_com_be-fr_contentful_pages"
const INDEX_SEARCH_NL_NL = process.env.GATSBY_ALGOLIA_INDEX_NL_CONTENTFUL_PAGES || "cotedor_com_nl_contentful_pages_dev"

const algolia_index_partial_update = process.env.GATSBY_ACTIVE_ENV === "production" ? true : false

module.exports = {
    APP_ID,
    SEARCH_KEY,
    API_KEY,
    INDEX_RECIPE_NL_BE,
    INDEX_RECIPE_FR_BE,
    INDEX_RECIPE_NL_NL,
    INDEX_SEARCH_NL_BE,
    INDEX_SEARCH_FR_BE,
    INDEX_SEARCH_NL_NL,
    algolia_index_partial_update,
}
