const { fragment: LinkFragment } = require("../UI/Link/Link.fragment")

const query = (id, isPreview) => `
{
    faqSection(id: "${id}", preview: ${isPreview}) {
        internalTitle
        title
        faqItemsCollection(limit:20) {
            items{
              title
              content
              sys{
                id
              } 
            }   
        }
        linkItemsCollection(limit: 5){
          items{
            ${LinkFragment}
          }
        }
    }
}
`
module.exports = { query }
