const { fragment: LinkFragment } = require("../UI/Link/Link.fragment")
const query = (id, isPreview) => `
{
    recipesShowcaseSection(id: "${id}", preview: ${isPreview}) {
        internalTitle
        title
        recipeIds
        cta {
          ${LinkFragment}
        }
    }
}
`
module.exports = { query }
