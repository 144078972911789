const { fragment: RichTextFragment } = require("../RichTextModel/RichText.fragment")
const { fragment: ResponsiveImage } = require("../UI/ResponsiveImage/ResponsiveImage.fragment")
const { fragment: LinkFragment } = require("../UI/Link/Link.fragment")
const query = (id, isPreview) => `
{
    tabSection(id: "${id}", preview: ${isPreview}) {
        internalTitle
        contentCollection(limit:10) {
            items{
              sys {
                id
              }
              buttonIcon {
                ${ResponsiveImage}
              }
              buttonLabel
              contentSubtitle
              contentTitle
              contentMedia {
                ${ResponsiveImage}
              }
              contentCopyColumn1 {
                ${RichTextFragment}
              }
              contentCopyColumn2 {
                ${RichTextFragment}
              }
              contentCta {
                ${LinkFragment}
              }
            nextTabButtonLabel    
            }
        }
    }
}
`
module.exports = { query }
