import React from "react"

function Stare() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.83355 3.72109V10.0327H1.00021V2.36601C1.00021 1.30897 1.82246 0.449341 2.83355 0.449341H10.1669V2.36601H4.12971L15.8566 14.626L14.5605 15.981L2.83355 3.72109ZM13.8335 4.28267C12.3064 4.28267 10.8681 4.68038 9.60038 5.37422L10.9717 6.80788C11.8517 6.41592 12.8188 6.19934 13.8335 6.19934C17.877 6.19934 21.1669 9.6388 21.1669 13.866C21.1669 18.0932 17.877 21.5327 13.8335 21.5327C9.79013 21.5327 6.50021 18.0932 6.50021 13.866C6.50021 12.8051 6.7083 11.7941 7.0823 10.8741L5.71096 9.44042C5.0473 10.7658 4.66688 12.2694 4.66688 13.866C4.66688 19.1503 8.77905 23.4493 13.8335 23.4493C18.888 23.4493 23.0002 19.1503 23.0002 13.866C23.0002 8.58176 18.888 4.28267 13.8335 4.28267Z"
                fill="#1D1D1B"
            />
        </svg>
    )
}

export default Stare
