import React from "react"
import PropTypes from "prop-types"
import * as styles from "./BannerCard-tw-styles"
import { generateLayoutClasses } from "./BannerCard.helper"
import Link from "../Link/Link"
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage"
import RichTextModel from "../../RichTextModel/RichTextModel"

function BannerCard(props) {
    const {
        link,
        image,
        content,
        gridClasses,
        mobileLayout = "Mobile_Column_ImageTop_ContentBottom",
        desktopLayout = "Desktop_Row_ImageLeft_ContentRight",
        variant,
    } = props
    const style = styles?.[variant] ?? styles.defaultStyle

    const layoutClasses = generateLayoutClasses(mobileLayout, desktopLayout)
    const BannerCardContent = () => {
        return (
            <div className={layoutClasses}>
                <div className={style.imgWrapper}>
                    <ResponsiveImage contentfulImagesComponent={image} className={style.img} />
                </div>
                <div className={style.text}>
                    <RichTextModel richText={content} style={style?.richTextProps} />
                </div>
            </div>
        )
    }
    if (!!link) {
        return (
            <Link {...link} className={`${style.wrapper} ${gridClasses}`}>
                <BannerCardContent />
            </Link>
        )
    } else {
        return (
            <div className={`${style.wrapper} ${gridClasses}`}>
                <BannerCardContent />
            </div>
        )
    }
}

export default BannerCard

BannerCard.propTypes = {
    link: PropTypes.object,
    image: PropTypes.object,
    content: PropTypes.object,
    gridClasses: PropTypes.string,
    mobileLayout: PropTypes.oneOf([
        "Mobile_Column_ImageTop_ContentBottom",
        "Mobile_Column_ImageBottom_ContentTop",
        "Mobile_Row_ImageLeft_ContentRight",
        "Mobile_Row_ImageRight_ContentLeft",
    ]),
    desktopLayout: PropTypes.oneOf([
        "Desktop_Row_ImageLeft_ContentRight",
        "Desktop_Row_ImageRight_ContentLeft",
        "Desktop_Column_ImageTop_ContentBottom",
        "Desktop_Column_ImageBottom_ContentTop",
    ]),
    variant: PropTypes.string,
}
