const query = (id, isPreview) => `
{
    productListingSection(id: "${id}", preview: ${isPreview}) {
        internalTitle
        title
        filtersCollection {
            items {
                ... on ProductFilter {
                    label
                    id
                    subcategoriesCollection {
                        items {
                            ... on ProductSubcategoryFilter {
                                label
                                id
                            }
                        }
                    }
                }
            }
        }
        loadMoreButtonLabel
    }
}
`
module.exports = { query }
