const { fragment: CocoaProfileBlockFragment } = require("../UI/CocoaProfileBlock/CocoaProfileBlock.fragment")

const query = (id, isPreview) => `
{
    cocoaLifeCarousel(id: "${id}", preview: ${isPreview}) {
        title
        itemsCollection {
            items {
                ... on CocoaProfileBlock {
                    ${CocoaProfileBlockFragment}
                }
            }
        }
    }
}
`
module.exports = { query }
