export const SECTION = {
    wrapper: "relative text-center pb-[80px] mlg:pb-[128px] mlg:pt-[50px] lg:pt-[unset]",
    content: "section-wrapper",
    title: "specialHeading-1 mb-[40px] pt-[32px] lg:pt-[0] lg:mt-[32px] lg:mb-[80px] xxl:mt-[128px]",
    buttons: "relative flex flex-row justify-center items-center gap-[16px] mx-[20px]",
    button: "btn-primary flex-[1] md:flex-none whitespace-nowrap",
}

export const BLOCK = {
    wrapper: "relative mb-[40px] block lg:mb-[80px]",
    content: "section-wrapper",
    buttonsWrapper:
        "relative max-w-[1144px] px-[20px] pb-[2px] no-scrollbar gap-x-[6px] mx-auto overflow-x-auto flex flex-row " +
        "md:justify-around md:items-center md:flex-wrap md:gap-x-[8px] md:gap-y-[16px] md:justify-center " +
        "lg:justify-center lg:px-0 xxl:max-w-[2100px] ",
    button: "btn-secondary md:w-[calc(20%_-_8px)] lg:w-auto lg:min-w-[184px] lg:border-2 xxl:border-3 xxl:min-w-[330px]",
    arrows: "hidden md:flex justify-between items-center",
    periodInfoWrapper:
        "text-white px-[20px] mt-[24px] mb-[32px] lg:mt-[128px] lg:max-w-[909px] lg:mx-auto xxl:mb-[80px] xxl:max-w-[1646px]",
    periodInfo: {
        period: "display-2 mb-[14px] lg:mb-[40px] xxl:mb-[80px]",
        title: "heading-2 mb-[14px] lg:mb-[40px] ",
        description: "body-1 lg:mb-[32px]",
    },
    carouselWrapper: "w-auto mlg:px-[32px]",
    arrowNext:
        "[&>svg]:w-[22px] [&>svg]:h-[22px] md:[&>svg]:h-[33px] xxl:[&>svg]:w-auto xxl:[&>svg]:h-auto mr-[3.5%] lg:mr-[0]",
    arrowPrev:
        "[&>svg]:w-[22px] [&>svg]:h-[22px] md:[&>svg]:h-[33px] xxl:[&>svg]:w-auto xxl:[&>svg]:h-auto ml-[3.5%] lg:ml-[0]",
}

export const CARD = {
    wrapper:
        "relative p-[10px] h-full w-[348px] md:min-w-[348px] md:min-h-[476px] lg:min-h-[480px] mlg:min-w-[250px] mlg:w-[unset] lg:min-w-[332px] lg:w-auto xxl:min-w-[608px] xxl:min-h-[561px]",
    innerWrapper:
        "relative rounded-[10px] border-4 border-beige-50 bg-white shadow-[4px_4px_0px_0px_#000] h-full text-left " +
        "px-[7px] pt-[7px] pb-[16px] " +
        "md:px-[12px] md:pt-[10px] md:pb-[18px] lg:pb-[34px] xxl:pb-[80px]",
    image: "rounded-[5px] max-h-[266px] object-cover w-full xxl:max-h-[312px]",
    title: "my-[16px] md:my-[18px] heading-2",
    description: "body-1",
}
