import React from "react"
import PropTypes from "prop-types"
import useInfiniteScroll from "../../../hooks/useInfiniteScroll"

function LoadMoreButton(props) {
    const { label, callback } = props
    const contentRef = useInfiniteScroll(callback)

    return (
        <div className="flex flex-col items-center justify-center gap-[32px]" ref={contentRef}>
            <svg
                className="w-[78px] h-[78px] md:h-[98px] md:w-[98px]"
                width="98"
                height="98"
                viewBox="0 0 98 98"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M49 0.999998C75.5097 0.999997 97 22.4903 97 49C97 75.5097 75.5097 97 49 97C22.4903 97 0.999999 75.5097 0.999998 49"
                    stroke="#DEB36C"
                    className="rotate-svg-circle"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M49.2909 49.6778L41.5137 57.4549L42.2209 58.162L49.998 50.3849L57.7772 58.1641L58.4843 57.457L50.7051 49.6778L59.1914 41.1915L58.4843 40.4844L49.998 48.9707L41.5137 40.4865L40.8066 41.1936L49.2909 49.6778Z"
                    fill="black"
                />
            </svg>
            <span className="body-2">{label}</span>
        </div>
    )
}

export default LoadMoreButton

LoadMoreButton.propTypes = {
    label: PropTypes.string,
    callback: PropTypes.func,
}
