import React, { useRef, useState } from "react"
import { BLOCK as style } from "./HistoryCarousel-tw-styles"
import HistoryCard from "./HistoryCard"
import Slider from "react-slick"
import "../../styles/slider.css"
import useScreenSize from "../../hooks/useScreenSize"
import clsx from "clsx"
import { gaEventClasses } from "../../constants/gtm.constants"
import { NextArrow, PrevArrow } from "../UI/Carousel/Arrows"
import Typography from "../UI/Typography/Typography"
import PropTypes from "prop-types"

const HistoryCarouselBlocks = ({ periods }) => {
    const [selectedPeriod, setSelectedPeriod] = useState([0, periods[0].cardsCollection.items.length - 1 || 0])
    const [selectedPeriodData, setSelectedPeriodData] = useState(periods[0])
    const carouselRef = useRef(null)
    const windowSize = useScreenSize()?.width
    let count = 0
    const lastBlockAmount = periods[periods.length - 1].cardsCollection.items.length

    for (let i = 0; i < periods.length; i++) {
        periods[i].firstSlideIndex = [count, count + periods[i].cardsCollection.items.length - 1]
        count += periods[i].cardsCollection.items.length
    }

    const onChangeCard = (_, index) => {
        periods.forEach(period => {
            if (period.firstSlideIndex.includes(index)) {
                setSelectedPeriod(period.firstSlideIndex)
                setSelectedPeriodData(prevData => (prevData !== period ? period : prevData))
            }
        })
    }

    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        beforeChange: onChangeCard,
        nextArrow: <NextArrow buttonStyles={style.arrowNext} />,
        prevArrow: <PrevArrow buttonStyles={style.arrowPrev} />,
        reinit: () => carouselRef.current.slickGoTo(selectedPeriod[0]),
        className: "history-carousel-slick",
        infinite: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "18px",
                    dots: false,
                    arrows: true,
                    variableWidth: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "10px",
                    variableWidth: false,
                    dots: false,
                },
            },
        ],
    }

    return (
        <div className={style.wrapper}>
            <div className={style.buttonsWrapper}>
                {/*TODO no mobile scroll to selected button when change*/}
                {periods?.map((item, i) => (
                    <button
                        key={`${i}-${item.sys.id}`}
                        aria-selected={item.firstSlideIndex.toString() === selectedPeriod.toString()}
                        className={clsx(style.button, gaEventClasses.button_click)}
                        data-action-detail={item.years}
                        onClick={() => {
                            carouselRef.current.slickGoTo(item.firstSlideIndex[0])
                        }}
                    >
                        {item.years}
                    </button>
                ))}
            </div>
            <div className={style.periodInfoWrapper} aria-live="polite">
                <h2 className={style.periodInfo.period}>{selectedPeriodData.years}</h2>
                <h3 className={style.periodInfo.title}>{selectedPeriodData.title}</h3>
                <Typography className={style.periodInfo.description} content={selectedPeriodData.description} />
            </div>
            <div className={style.carouselWrapper}>
                <Slider ref={carouselRef} {...settings}>
                    {periods?.map((item, i) => {
                        return item.cardsCollection.items.map((card, index) => (
                            <HistoryCard key={`${i}-${index}`} {...card} />
                        ))
                    })}
                    {lastBlockAmount < 4 &&
                        windowSize >= 1440 &&
                        [...Array(4 - lastBlockAmount)].map((_, i) => (
                            <div key={`${i}-empty-cards`} className="hidden" /> //to ensure last button is selected if there are less than 4 cards
                        ))}
                </Slider>
            </div>
        </div>
    )
}

HistoryCarouselBlocks.propTypes = {
    periods: PropTypes.array,
}

export default HistoryCarouselBlocks
