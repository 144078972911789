import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography"
import * as styles from "./FaqSection-tw-styles"
import useScreenSize from "../../hooks/useScreenSize"
import GoldDropdown from "../../assets/icons/GoldDropdown"
import GoldUpArrow from "../../assets/icons/GoldUpArrow"

function FaqItem(props) {
    const style = styles.defaultStyle
    const { title, content, isOpen, setOpenedIndex, index } = props
    const [height, setHeight] = useState()
    const expandedContent = useRef()
    const windowSize = useScreenSize()
    useEffect(() => {
        if (isOpen) {
            setHeight(expandedContent.current?.getBoundingClientRect().height)
        } else {
            setHeight(0)
        }
    }, [isOpen, windowSize])

    const toggleExpandedState = () => {
        setOpenedIndex(!isOpen ? index : -1)
    }
    if (!content || !title) return null

    return (
        <div className={style?.faqContainer}>
            <button className={isOpen ? style?.faqHeader : style?.faqHeaderOpen} onClick={toggleExpandedState}>
                <Typography className={style.faqQuestion} content={title} />
                <div className={style.arrow}>
                    {isOpen ? <GoldUpArrow className={style.arrow} /> : <GoldDropdown className={style.arrow} />}
                </div>
            </button>
            <div className={style?.expandedContainer} style={{ height }} onClick={toggleExpandedState}>
                <div ref={expandedContent} className={style?.expandedTextContainer}>
                    <Typography className={style.faqAnswer} content={content} />
                </div>
            </div>
        </div>
    )
}

export default FaqItem

FaqItem.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    isOpen: PropTypes.bool,
    setOpenedIndex: PropTypes.func,
    index: PropTypes.number,
}
