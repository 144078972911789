import React from "react"
import PropTypes from "prop-types"
import Link from "../UI/Link/Link"
import Slider from "react-slick"
import LinkCard from "../UI/LinkCard/LinkCard"
import { getRecipeUrl } from "../../constants/locale.constants"
import { getRecipeMedia } from "../../helpers/tastehub.helper"

const RecipesShowcaseSection = props => {
    const { style, title, cta, recipes } = props
    const settings = {
        className: "recipe-showcase-cards-slick",
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        centerMode: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 2559,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    centerMode: false,
                    variableWidth: true,
                },
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    variableWidth: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    variableWidth: true,
                },
            },
        ],
    }
    return (
        <div className={style.wrapper}>
            <div className={style.contentWrapper}>
                {!!title && <h2 className={style.title}>{title}</h2>}
                {Array.isArray(recipes) && recipes.length > 0 && (
                    <div className={style?.sliderWrapper}>
                        <Slider {...settings}>
                            {recipes.map(recipe => {
                                const cardImage = getRecipeMedia(recipe).cardImage
                                const linkCardProps = {
                                    variant: "RECIPE_CARD",
                                    link: {
                                        url: getRecipeUrl(recipe),
                                        label: recipe.title,
                                        title: recipe.title,
                                        gaEventClasses: "",
                                        gaEventLabels: "",
                                    },
                                    backgroundImage: {
                                        imageDesktop: {
                                            title: recipe.title,
                                            url: cardImage?.image_url,
                                            width: cardImage?.image_type?.width,
                                            height: cardImage?.image_type?.height,
                                        },
                                    },
                                    gridClasses: null,
                                }
                                return <LinkCard key={`featured_recipes_${recipe.id}`} {...linkCardProps} />
                            })}
                        </Slider>
                    </div>
                )}
                {!!cta && <Link {...cta} className={style?.cta} />}
            </div>
        </div>
    )
}

RecipesShowcaseSection.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    cta: PropTypes.object,
    recipes: PropTypes.array,
}

export default RecipesShowcaseSection
