export const defaultStyle = {
    wrapper: "relative",
    intro: "relative",
    introContent: "section-wrapper flex flex-col items-center gap-[80px] pt-[60px] pb-[48px] lg:py-[32px]",
    title: "specialHeading-1 text-center",
    searchBar: "relative w-full flex flex-row gap-2",
    searchInput:
        "w-full bg-transparent outline-none heading-3 font-extralight text-white placeholder-white placeholder-opacity-70 px-1 peer",
    searchButton: "[&_svg_path]:fill-white",
    searchBarBorder:
        "absolute left-0 bottom-[-5px] h-[2px] right-0 pointer-events-none transition transition-bg bg-white opacity-40 peer-focus:bg-gold-500 peer-focus:opacity-100",
    results: "bg-white",
    resultsContent: "section-wrapper flex flex-col gap-[80px] py-[80px]",
    resultsTitle: "heading-2",
    resultsTitleCentered: "heading-2 text-center",
    resultsList: "",
    resultsLi: "first:pt-[0] first:pb-[32px] py-[32px] border-b-2 border-gray-400 group",
    resultWrapper: "grid grid-cols-1 mlg:grid-cols-2 gap-[32px]",
    resultTitle: "heading-4 row-start-1 mlg:col-start-1",
    resultDescription: "body-2 font-extralight row-start-3 mlg:row-start-2 mlg:col-start-1 group-hover:underline",
    resultImageGrid: "row-start-2 mlg:row-start-1 mlg:col-start-2 mlg:row-span-2 mlg:ml-auto",
    resultImageWrapper:
        "w-[168px] mlg:w-[216px] xxl:w-[400px] h-[168px] xxl:h-[197px]  overflow-hidden mlg:justify-items-end",
    resultImage: "w-full h-full object-cover rounded-[10px]",
    loadMoreWrapper: "flex justify-center",
}
