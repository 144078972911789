const { fragment: LinkFragment } = require("../Link/Link.fragment")
const { fragment: CocoaProfileCardFragment } = require("../CocoaProfileCard/CocoaProfileCard.fragment")

const fragment = `
    sys {
        id
    }
    card {
        ${CocoaProfileCardFragment}
    }
    link {
        ${LinkFragment}
    }
`
module.exports = { fragment }
