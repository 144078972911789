import { useEffect, useState } from "react"
import { navigate } from "gatsby"

const useRecipeSearch = ({ selectedCategoryInitial }) => {
    const [searchTerm, setSearchTerm] = useState("")
    const [selectedCategory, setSelectedCategory] = useState(selectedCategoryInitial)

    useEffect(() => {
        const updateFromURL = () => {
            if (typeof window !== "undefined") {
                const params = new URLSearchParams(window.location.search)
                const search = params.get("search")
                const category = params.get("category")

                if (search) {
                    setSearchTerm(search)
                } else {
                    setSearchTerm("")
                }
                if (category) {
                    setSelectedCategory(category)
                } else {
                    setSelectedCategory(selectedCategoryInitial)
                }
            }
        }

        updateFromURL()

        window.addEventListener("popstate", updateFromURL)
        return () => {
            window.removeEventListener("popstate", updateFromURL)
        }
    }, [selectedCategoryInitial])

    const handleKeyDown = e => {
        if (e.keyCode === 13) {
            handleButtonClick()
        }
    }

    const handleButtonClick = () => {
        setSelectedCategory(selectedCategoryInitial)
        setTimeout(() => {
            updateURLParams(searchTerm, selectedCategoryInitial)
        }, 0)
    }

    const handleCategoryChange = id => {
        setSearchTerm("")
        setSelectedCategory(id)
        setTimeout(() => {
            updateURLParams("", id)
        }, 0)
    }

    const updateURLParams = (searchTerm, selectedCategory) => {
        let url = "?"
        if (searchTerm.trim().length > 0) {
            url += `search=${searchTerm}`
        }
        if (selectedCategory && selectedCategory !== selectedCategoryInitial) {
            url += `${url.length > 1 ? "&" : ""}category=${selectedCategory}`
        }
        navigate(url)
    }

    return {
        searchTerm,
        setSearchTerm,
        handleKeyDown,
        handleButtonClick,
        handleCategoryChange,
        selectedCategory,
    }
}

export default useRecipeSearch
