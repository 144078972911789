const { fragment: RichTextFragment } = require("../RichTextModel/RichText.fragment")
const { fragment: LinkFragment } = require("../UI/Link/Link.fragment")
const { fragment: CocoaProfileLinkFragment } = require("../UI/CocoaProfileLink/CocoaProfileLink.fragment")

const query = (id, isPreview) => `
{
    cocoaLifeSection(id: "${id}", preview: ${isPreview}) {
        title
        icon {
            url
            title
            description
            width
            height
        }
        description {
            ${RichTextFragment}
        }
        cta {
            ${LinkFragment}
        }
        cocoaProfileLinksCollection(limit: 4) {
            items {
                ... on CocoaProfileLink {
                    ${CocoaProfileLinkFragment}
                }
            }
        }
    }
}
`
module.exports = { query }
