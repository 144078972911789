export const defaultStyle = {
    titleWrapper: "relative bg-transparent",
    titleSectionWrapper: "section-wrapper pt-[32px] md:pt-[108px] pb-[24px] lg:pb-[62px] xxl:pb-[32px]",
    title: "specialHeading-1 text-center",
    filtersWrapper: "bg-transparent lg:bg-transparent-top-white-bottom-half xxl:bg-white mb-[-1px]",
    filtersSectionWrapper: "section-wrapper py-[18px] xxl:pb-[36px]",
    filtersGrid:
        "grid grid-cols-2 gap-[16px] max-w-[354px] mx-auto mlg:max-w-[unset] mlg:flex mlg:justify-center lg:justify-between",
    filterWrapper: "col-span-1 first-of-type:col-span-2 lg:col-span-[unset] flex items-center justify-center lg:flex-1",
    filterButton: "w-full btn-secondary h-full lg:!border-[2px]",
    searchBar: "relative w-full flex flex-row gap-2",
    searchInput:
        "w-full bg-transparent outline-none heading-3 font-extralight text-black placeholder-black placeholder-opacity-70 px-1 peer",
    searchButton: "[&_svg_path]:fill-black",
    searchBarBorder:
        "absolute left-0 bottom-[-5px] h-[2px] right-0 pointer-events-none transition transition-bg bg-gold-500 opacity-40 peer-focus:bg-gold-500 peer-focus:opacity-100",
    searchBarWrapper: "bg-white py-[40px]",
    searchBarSectionWrapper: "section-wrapper",
    recipeListWrapper: "bg-white",
    recipeListSectionWrapper: "section-wrapper pb-[80px] flex flex-col",
    recipeList: {
        grid: "grid grid-cols-1 gap-x-[16px] gap-y-[32px] md:gap-y-[48px] md:grid-cols-2 mlg:grid-cols-3 xxl:grid-cols-4",
        cardWrapper: "w-full [&>a>div]:!mx-0 [&>a>div]:max-w-[unset] [&>a>div]:w-[unset]",
        error: "w-full flex items-center justify-center ",
        notFound: "w-full flex items-center justify-center mt-[80px] xxl:mt-[120px]",
        loadMoreWrapper: "w-full flex items-center justify-center mt-[80px] xxl:mt-[120px]",
    },
}
