export const defaultStyle = {
    tabWrapper: "w-full px-[16px] pt-[80px] pb-[116px] md:py-[80px]",
    tabElements:
        "grid grid-cols-2 w-full gap-[20px] md:flex md:justify-center md:max-w-[1143px] xxl:max-w-[1641px] mx-auto",
    iconWrapper: "w-full flex flex-col gap-[16px] lg:gap-[32px] items-center",
    iconImg: "w-[74px] h-[74px] lg:w-[99px] lg:h-[99px] xxl:w-[144px] xxl:h-[144px]",
    buttonLabel: "w-full btn-secondary w-auto md:w-[169px] lg:w-[216px] xxl:w-[302px] whitespace-nowrap !border-[2px]",
    contentContainer: "mt-[80px] lg:mt-[128px]",
    titleWrapper: "pb-[6px] lg:pb-[0px] text-center",
    contentSubtitle: "heading-3 text-gold-300 pb-[26px] lg:pb-[32px]",
    contentTitle: "heading-2 text-white",
    contentWrapper: "section-wrapper flex flex-col flex-auto xxl:flex-row xxl:gap-[52px] py-[30px] lg:py-[52px]",
    contentImgWrapper: "mx-auto xxl:flex-1",
    textContent:
        "flex flex-col md:flex-row md:gap-[32px] xxl:flex-col text-white mt-[22px] xxl:mt-[0] mx-auto xxl:flex-1",
    contentImg:
        "rounded-[10px] shadow-[3px_3px_0px_0px_rgba(0,0,0,1)] xxl:shadow-[0px_2px_0px_0px_rgba(0,0,0,1)]  object-cover h-[352px] md:h-[532px] xxl:h-full",
    contentCopyColumn1: {
        wrapper: "flex-1",
        heading4: "heading-4 pt-[27px] xxl:pt-[0]",
    },
    contentCopyColumn2: {
        wrapper: "flex-1",
        ulList: "pt-[27px] xxl:pt-[0]",
        paragraph: "body-2  xxl:px-[0] xxl:py-[20px] first:pt-[27px] pt-[36px]",
    },
    buttonWrapper: "flex justify-center h-[60px] my-[32px]",
    bottomButton: "text-center btn-primary lg:max-w-[250px] ",
}
