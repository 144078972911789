const { fragment: LinkCardFragment } = require("../UI/LinkCard/LinkCard.fragment")
const { fragment: BannerCardFragment } = require("../UI/BannerCard/BannerCard.fragment")

const query = (id, isPreview) => `
{
    gridSection(id: "${id}", preview: ${isPreview}) {
        internalTitle
        title
        subtitle
        itemsCollection(limit:10) {
            items {
                internalTitle
                content {
                    __typename
                    ... on LinkCard {
                        ${LinkCardFragment}
                    }
                    ... on BannerCard {
                        ${BannerCardFragment}
                    }
                }
                gridClasses
            }
        }
        variant
    }
}
`
module.exports = { query }
