export const defaultStyle = {
    wrapper: "relative pt-[16px] lg:pt-[64px] pb-[128px] xxl:pt-[120px] xxl:pb-[240px]",
    content: "section-wrapper flex flex-col gap-[42px] lg:gap-[80px] xxl:gap-[128px]",
    title: "specialHeading-2 text-center",
    containerMobile: {
        wrapper: "flex flex-col gap-[32px]",
        firstCardWrapper:
            "flex items-center justify-between gap-[24px] md:gap-[32px] p-[16px] bannerCardGradient rounded-[10px] max-w-[708px] mx-auto",
        icon: "w-[97px] h-[98px]",
        description: {
            richTextWrapper: "flex-1 text-white",
            code: "hidden",
            link: "",
            paragraph: "body-2 [&_a]:hover:underline",
            heading1: "hidden",
            heading2: "hidden",
            heading3: "hidden",
            heading4: "hidden",
            heading5: "hidden",
            heading6: "hidden",
            table: "hidden",
            tRow: "hidden",
            tHeader: "hidden",
            tData: "hidden",
            ulList: "hidden",
            list: "hidden",
            title: "hidden",
            mainLink: "hidden",
        },
        sliderWrapper: "w-[calc(100%+40px)] ml-[-20px] md:w-[calc(100%+32px)] md:ml-[-16px]",
        cta: "btn-primary w-fit-content mx-auto",
    },
    containerDesktop: {
        wrapper: "w-full flex flex-row gap-[16px] justify-center",

        firstCardWrapper:
            "flex flex-col items-center justify-between gap-[24px] px-[20px] py-[40px] bannerCardGradient rounded-[10px] w-[310px] sm:w-[353px] md:w-[340px] lg:flex-grow mlg:min-w-[240px] mlg:w-full lg:min-w-[332px] lg:w-full lg:max-w-[612px]",

        icon: "lg:w-[131px] lg:h-[132px] xxl:w-[186px] xxl:h-[187px]",
        description: {
            richTextWrapper: "text-white",
            code: "hidden",
            link: "",
            paragraph: "body-2 [&_a]:hover:underline",
            heading1: "hidden",
            heading2: "hidden",
            heading3: "hidden",
            heading4: "hidden",
            heading5: "hidden",
            heading6: "hidden",
            table: "hidden",
            tRow: "hidden",
            tHeader: "hidden",
            tData: "hidden",
            ulList: "hidden",
            list: "hidden",
            title: "hidden",
            mainLink: "hidden",
        },
        cta: "btn-primary w-fit-content mx-auto",
    },
}
