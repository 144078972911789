const { fragment: LinkFragment } = require("../UI/Link/Link.fragment")
const { fragment: ResponsiveImageFragment } = require("../UI/ResponsiveImage/ResponsiveImage.fragment")

const query = (id, isPreview) => `
{
    historyCarousel(id: "${id}", preview: ${isPreview}) {
        title
        periodsCollection(limit: 15){
          items{
            sys {
              id
            }
            years
            title
            description
            cardsCollection(limit: 20){
              items{
                sys {
                  id
                }
                image{
                  ${ResponsiveImageFragment}
                }
                year
                description
              }
            }
          }
        }
        linksCollection(limit: 5){
          items{
            ${LinkFragment}
          }
        }
    }
}
`
module.exports = { query }
