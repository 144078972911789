import React from "react"
import PropTypes from "prop-types"
import ResponsiveImage from "../UI/ResponsiveImage/ResponsiveImage"
import { gaEventClasses } from "../../constants/gtm.constants"

function Tabs(props) {
    const { buttonIcon, buttonLabel, isActive, onClick, style } = props

    return (
        <div className={style.iconWrapper}>
            <ResponsiveImage contentfulImagesComponent={buttonIcon} className={style.iconImg} />
            <button
                className={`${style.buttonLabel} ${gaEventClasses?.button_click || ""}`}
                data-action-detail={`History Tab: ${buttonLabel}`}
                aria-selected={isActive.toString()}
                onClick={onClick}
            >
                {buttonLabel}
            </button>
        </div>
    )
}

Tabs.propTypes = {
    buttonIcon: PropTypes.object,
    buttonLabel: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
}

export default Tabs
