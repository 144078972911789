import { useState, useEffect } from "react"
import { navigate } from "gatsby"

function useProductListing({ filters }) {
    const [selectedCategory, setSelectedCategory] = useState(filters[0])
    useEffect(() => {
        const updateFromURL = () => {
            if (typeof window !== "undefined") {
                const params = new URLSearchParams(window.location.search)
                const categoryId = params.get("category")
                const foundCategory = filters.find(filter => filter.id === categoryId)
                if (categoryId && foundCategory) {
                    setSelectedCategory(foundCategory)
                } else {
                    setSelectedCategory(filters[0])
                }
            }
        }

        updateFromURL()

        window.addEventListener("popstate", updateFromURL)
        return () => {
            window.removeEventListener("popstate", updateFromURL)
        }
    }, [filters[0]])

    const handleCategoryChange = category => {
        setSelectedCategory(category)
        setTimeout(() => {
            updateURLParams(category)
        }, 0)
    }

    const updateURLParams = selectedCategory => {
        let url = "?"

        if (selectedCategory?.id && selectedCategory.id !== filters[0].id) {
            url += `category=${selectedCategory.id}`
        }

        navigate(url)
    }
    return [selectedCategory, handleCategoryChange]
}

export default useProductListing
