import React from "react"
import PropTypes from "prop-types"
import BannerCard from "../UI/BannerCard/BannerCard"
import LinkCard from "../UI/LinkCard/LinkCard"
import Typography from "../UI/Typography/Typography"

const GridSection = props => {
    const { style, title, subtitle, itemsCollection } = props
    return (
        <div className={style?.wrapper}>
            <div className={style?.contentWrapper}>
                {!!title && <h2 className={style?.title}>{title}</h2>}
                {subtitle && <Typography className={style.subtitle} content={subtitle} />}
                <div className={style?.gridWrapper}>
                    {itemsCollection.items.map((item, index) => (
                        <React.Fragment key={`${item.content.sys.id}-${index}`}>
                            {item.content?.__typename === "BannerCard" && (
                                <BannerCard {...item.content} gridClasses={item.gridClasses} />
                            )}
                            {item.content?.__typename === "LinkCard" && (
                                <LinkCard {...item.content} gridClasses={item.gridClasses} />
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    )
}

GridSection.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    itemsCollection: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.object),
    }),
}

export default GridSection
