import React from "react"
import PropTypes from "prop-types"

function GoldDropdown({ className }) {
    return (
        <svg
            className={className}
            width="35"
            height="24"
            viewBox="0 0 35 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_1152_5303)">
                <mask id="path-1-inside-1_1152_5303" fill="white">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.27343 -1.1995e-06L0.285152 4.98828L16.5 21.2031L32.7148 4.98828L27.7266 -2.18045e-07L16.5 11.2266L5.27343 -1.1995e-06Z"
                    />
                </mask>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.27343 -1.1995e-06L0.285152 4.98828L16.5 21.2031L32.7148 4.98828L27.7266 -2.18045e-07L16.5 11.2266L5.27343 -1.1995e-06Z"
                    fill="white"
                />
                <path
                    d="M0.285152 4.98828L-1.12906 3.57407L-2.54327 4.98828L-1.12906 6.40249L0.285152 4.98828ZM5.27343 -1.1995e-06L6.68765 -1.41421L5.27343 -2.82843L3.85922 -1.41421L5.27343 -1.1995e-06ZM16.5 21.2031L15.0858 22.6173L16.5 24.0316L17.9142 22.6173L16.5 21.2031ZM32.7148 4.98828L34.1291 6.40249L35.5433 4.98828L34.1291 3.57407L32.7148 4.98828ZM27.7266 -2.18045e-07L29.1408 -1.41421L27.7266 -2.82843L26.3123 -1.41421L27.7266 -2.18045e-07ZM16.5 11.2266L15.0858 12.6408L16.5 14.055L17.9142 12.6408L16.5 11.2266ZM1.69937 6.40249L6.68765 1.41421L3.85922 -1.41421L-1.12906 3.57407L1.69937 6.40249ZM17.9142 19.7889L1.69937 3.57407L-1.12906 6.40249L15.0858 22.6173L17.9142 19.7889ZM31.3006 3.57407L15.0858 19.7889L17.9142 22.6173L34.1291 6.40249L31.3006 3.57407ZM26.3123 1.41421L31.3006 6.40249L34.1291 3.57407L29.1408 -1.41421L26.3123 1.41421ZM26.3123 -1.41421L15.0858 9.81235L17.9142 12.6408L29.1408 1.41421L26.3123 -1.41421ZM17.9142 9.81235L6.68765 -1.41421L3.85922 1.41421L15.0858 12.6408L17.9142 9.81235Z"
                    fill="#DEB36C"
                    mask="url(#path-1-inside-1_1152_5303)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_1152_5303"
                    x="0.285156"
                    y="0"
                    width="34.4297"
                    height="23.2031"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="2" dy="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1152_5303" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1152_5303" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default GoldDropdown

GoldDropdown.propTypes = {
    className: PropTypes.string,
}
