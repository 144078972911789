export const defaultStyle = {
    wrapper: "relative overflow-hidden bannerCardGradient rounded-xl group h-[220px] lg:h-[400px] xxl:h-[503px]",
    imgWrapper: "relative overflow-hidden flex-1 rounded-xl",
    img: "absolute inset-0 object-cover object-center w-full h-full transition-transform duration-300 ease-in-out group-hover:scale-125",
    text: "flex flex-1 items-center justify-center",
    richTextProps: {
        paragraph: "body-2 text-white px-4 pb-[16px]",
        heading3: "heading-3 text-white mb-4 lg:mb-8 px-4",
    },
}

export const NOTRE_VISION_CARD = {
    wrapper: "w-full max-w-[353px] md:max-w-[unset] lg:w-[680px] xxl:w-[815px]",
    layoutWrapper: "relative flex flex-col h-full",
    imgWrapper:
        "relative overflow-hidden rounded-[10px] border-[3px] border-beige-500 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] w-full h-[220px] md:h-[275px] lg:h-[424px] xxl:h-[500px] md:mb-[19.2px] mb-[32px] lg:mb-[16px]",
    img: "absolute inset-0 object-cover object-center w-full h-full",
    text: "text-center text-sm md:text-base lg:text-lg",
    richTextProps: {
        paragraph: "text-black body-1 text-left ",
    },
}

export const NOS_PRINCIPES_CARD = {
    wrapper:
        "w-full max-w-[353px] md:max-w-[unset] xxl:w-[815px] rounded-[10px] overflow-hidden bg-beige-100 lg:min-h-[229px] xxl:min-h-[296px]",
    layoutWrapper: "relative flex flex-col h-full ",
    imgWrapper: "relative overflow-hidden w-[168px] md:w-[320px] lg:w-[216px] xxl:w-[380px] h-full",
    img: "absolute inset-0 object-cover object-center w-full h-full rounded-[10px]",
    text: "text-center w-[168px] md:w-[464px] mx-[16px] xxl:mx-[32px] my-[16px] md:my-[32px]",
    richTextProps: {
        paragraph: "text-black body-2 lighter text-left whitespace-normal",
        heading4: "text-left heading-4 text-black mb-[16px]",
    },
}
export const DEFAULT_SHADOW_HOVER = {
    wrapper:
        "relative overflow-hidden bannerCardGradient rounded-xl group h-[220px] lg:h-[400px] xxl:h-[503px] hover:shadow-[4px_4px_0px_#000000]",
    imgWrapper: "relative overflow-hidden flex-1 rounded-xl",
    img: "absolute inset-0 object-cover object-center w-full h-full transition-transform duration-300 ease-in-out group-hover:scale-125",
    text: "flex flex-1 items-center justify-center",
    richTextProps: {
        paragraph: "body-2 text-white px-4",
        heading3: "heading-3 text-white mb-4 lg:mb-8 px-4",
    },
}
