import { useEffect, useState } from "react"
import algoliaSearchInstance from "../service/AlgoliaService"

const useRecipeSearchResults = ({ language, defaultSearch = "", defaultCategory, hitsPerPage }) => {
    const [results, setResults] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    let search
    let category
    if (typeof window !== "undefined") {
        const urlParams = new URLSearchParams(window.location.search)
        search = urlParams.get("search") || defaultSearch
        category = urlParams.get("category") || defaultCategory
    }

    useEffect(() => {
        if (typeof window === "undefined") return

        const fetchResults = async () => {
            setLoading(true)
            setError(null)
            try {
                const searchOptions = {
                    hitsPerPage,
                }
                if (category !== defaultCategory) {
                    searchOptions.facetFilters = [`classifications.classification.id:${category}`]
                }
                const data = await algoliaSearchInstance.getSearchResults("recipe", language, search, searchOptions)
                setResults(data)
            } catch (err) {
                setError(err?.message || "An error occurred while fetching recipe results")
            } finally {
                setLoading(false)
            }
        }

        fetchResults()
    }, [language, search, category, hitsPerPage])

    return {
        results,
        loading,
        error,
    }
}

export default useRecipeSearchResults
