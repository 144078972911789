const query = (id, isPreview) => `
{
    recipeListingSection(id: "${id}", preview: ${isPreview}) {
        internalTitle
        title
        filtersCollection {
            items {
                ... on RecipeFilter {
                    label
                    id
                }
            }
        }
        searchPlaceholder
        language
        noRecipesFoundLabel
        loadMoreButtonLabel
    }
}
`
module.exports = { query }
