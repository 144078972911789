import React from "react"
import PropTypes from "prop-types"
import RecipeList from "./RecipeList"
import SearchIcon from "../../assets/icons/SearchIcon"
import useRecipeSearch from "../../hooks/useRecipeSearch"
import { gaEventClasses } from "../../constants/gtm.constants"

const RecipeListingSection = props => {
    const {
        style,
        title,
        filtersCollection,
        searchPlaceholder = "Search...",
        language,
        noRecipesFoundLabel,
        loadMoreButtonLabel,
    } = props
    const { items: filters } = filtersCollection
    const { searchTerm, setSearchTerm, handleKeyDown, handleButtonClick, selectedCategory, handleCategoryChange } =
        useRecipeSearch({ selectedCategoryInitial: String(filters[0].id) })

    return (
        <>
            <div className={style.titleWrapper}>
                <div className={style.titleSectionWrapper}>
                    <h2 className={style.title}>{title}</h2>
                </div>
            </div>
            <div className={style.filtersWrapper}>
                <div className={style.filtersSectionWrapper} id={"category-filters"}>
                    <div className={style.filtersGrid}>
                        {filters.map(item => {
                            return (
                                <div key={`rlp_filter_${item.id}`} className={style.filterWrapper}>
                                    <button
                                        id={`category_filter_button_${item.id}`}
                                        aria-selected={selectedCategory === item.id}
                                        onClick={() => {
                                            handleCategoryChange(String(item.id))
                                        }}
                                        className={`${style.filterButton} ${gaEventClasses?.button_click || ""}`}
                                        data-action-detail={`Filter ${item.label}`}
                                    >
                                        {item.label}
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className={style.searchBarWrapper}>
                <div className={style.searchBarSectionWrapper}>
                    <div className={style.searchBar}>
                        <input
                            placeholder={searchPlaceholder}
                            className={style.searchInput}
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <button
                            type="button"
                            aria-label={searchPlaceholder}
                            onClick={handleButtonClick}
                            className={`${style.searchButton} ${gaEventClasses?.button_click || ""}`}
                            data-action-detail={`Recipe search ${searchTerm}`}
                        >
                            <SearchIcon />
                        </button>
                        <div className={style.searchBarBorder}>&nbsp;</div>
                    </div>
                </div>
            </div>
            <div className={style.recipeListWrapper}>
                <div className={style.recipeListSectionWrapper}>
                    <RecipeList
                        language={language}
                        defaultCategory={String(filters[0].id)}
                        noRecipesFoundLabel={noRecipesFoundLabel}
                        loadMoreButtonLabel={loadMoreButtonLabel}
                        style={style.recipeList}
                    />
                </div>
            </div>
        </>
    )
}

RecipeListingSection.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    filtersCollection: PropTypes.object,
    searchPlaceholder: PropTypes.string,
    language: PropTypes.oneOf(["nl-BE", "fr-BE", "nl-NL"]),
    noRecipesFoundLabel: PropTypes.string,
    loadMoreButtonLabel: PropTypes.string,
}

export default RecipeListingSection
