export const defaultStyle = {
    wrapper: "relative",
    container: "relative",
    overlay: "hidden",
    description: {
        richTextWrapper: "hidden",
        code: "",
        link: "",
        paragraph: "",
        heading1: "",
        heading2: "",
        heading3: "",
        heading4: "",
        heading5: "",
        heading6: "",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "",
        list: "",
        title: "hidden",
        mainLink: "hidden",
    },
    bgWrapper: "absolute inset-0 bg-red",
    cta: "hover:underline",
}
export const HERO_SLIDE = {
    wrapper: "relative xxl:px-[41px] lg:px-[32px] md:px-[20px] px-[10px] mt-1 w-full ",
    container:
        "relative red-gradient-horizontal flex flex-col-reverse md:flex-col mlg:flex-row w-full h-full justify-between xxl:py-[78px] xxl:px-[40px] mlg:py-[34px] lg:px-[40px] md:pb-[35px] md:pt-[20px] md:px-[20px] py-[70px]",
    overlay: "hidden",
    description: {
        richTextWrapper:
            "flex flex-col items-center text-center text-white mlg:justify-center xxl:items-start mlg:items-start mlg:text-left md:mt-[13px] mt-[20px] ",
        code: "",
        link: "",
        paragraph: "xxl:mb-10 lg:mb-10 md:mb-6 mb-8",
        heading1: "display-1",
        heading2: "display-2 xxl:mb-[20px] lg:mb-[20px] md:max-w-none mb-[12px]",
        heading3: "heading-3-hero sm:mb-[32px] md:mb-[32px] mb-[64px]",
        heading4: "",
        heading5: "",
        heading6: "",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "",
        list: "",
        title: "hidden",
        mainLink: "hidden",
    },
    contentWrapper: "flex items-center justify-center md:order-first mlg:order-none xxl:w-1/2 mlg:w-auto md:w-full",
    cta: "btn-primary text-black",
    image: "object-contain xxl:max-w-[1200px] xxl:mt-0 mlg:mt-0 md:max-w-[430px] md:mt-7 max-w-[321px] w-full mlg:max-w-[656px] lg:max-w-[763px]",
}
export const PLP_BANNER = {
    wrapper:
        "relative w-full flex justify-center mx-auto lg:px-[40px] px-[20px] pb-[20px] pt-[86px] lg:pt-[54px] xxl:pt-[124px]",
    insetWrapper:
        "relative section-wrapper rounded-[12px] md:rounded-[14px] border-2 lg:border-4 border-gold-400 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] w-full h-[580px] md:h-[650px] mlg:h-[430px] lg:h-[608px] xxl:h-[854px]",
    container:
        "relative flex flex-col-reverse md:flex-col mlg:flex-row w-full h-full justify-between w-full " +
        "py-[108px] px-[19px] md:py-[50px] md:px-[20px]  mlg:py-[34px] mlg:px-[40px] xxl:py-[78px] xxl:px-[40px] ",
    overlay: "hidden",
    description: {
        richTextWrapper:
            "flex flex-col items-center text-center text-white mlg:justify-center xxl:items-start mlg:items-start mlg:text-left md:mt-[13px] mt-[20px] ",
        code: "",
        link: "",
        paragraph: "xxl:mb-10 lg:mb-10 md:mb-6 mb-8",
        heading1: "",
        heading2:
            "display-2 xxl:mb-[20px] xxl:max-w-[1000px] lg:mb-[20px] lg:max-w-[400px] md:max-w-none max-w-[309px] mb-[12px] z-[9]",
        heading3:
            "display-3 xxl:mb-[20px] xxl:max-w-[1000px] lg:mb-[20px] lg:max-w-[400px] md:max-w-none max-w-[309px] mb-[12px] z-[9]",
        heading4: "",
        heading5: "",
        heading6: "",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "",
        list: "",
        title: "hidden",
        mainLink: "hidden",
    },
    contentWrapper: "flex items-center justify-center md:order-first mlg:order-none xxl:w-1/2 mlg:w-auto md:w-full",
    cta: "btn-primary text-black",
    image: "object-contain xxl:max-w-[1200px] xxl:mt-0 mlg:max-w-[453px] mlg:mt-0 md:max-w-[330px] md:mt-7 max-w-[277px] w-full",
    bgWrapper: "absolute inset-0 w-full h-full",
    backgroundImage: "object-cover w-full h-full rounded-[10px]",
}

const titleOnly = {
    wrapper:
        "relative flex xxl:h-[946px] md:h-[622px] mlg:h-full lg:h-[708px] mlg:px-[40px] px-[20px] pt-[32px] mlg:pt-[84px] lg:pt-[54px] xxl:pt-[80px] pb-[20px] w-full bg-transparent-top-white-bottom-mobile md:bg-transparent-top-white-bottom-banner mb-[-1px]",
    insetWrapper:
        "section-wrapper relative rounded-[14px] border-4 border-gold-400 shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] md:shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] w-full h-[580px] mlg:h-[438px] lg:h-[608px] xxl:h-[854px]",
    container: "relative w-full h-full flex items-center justify-center",
    overlay: "hidden",
    description: {
        richTextWrapper: "relative flex items-center justify-center text-center w-full h-full",
        code: "hidden",
        link: "hidden",
        paragraph: "hidden",
        heading1: "specialHeading-1",
        heading2: "hidden",
        heading3: "hidden",
        heading4: "hidden",
        heading5: "specialHeading-3",
        heading6: "hidden",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "",
        list: "",
        title: "hidden",
        mainLink: "hidden",
    },
    contentWrapper: "hidden",
    bgWrapper: "absolute inset-0 w-full h-full",
    backgroundImage: "object-cover w-full h-full rounded-[10px]",
    cta: "hidden",
}

export const HISTORY_CHOCOLATE = {
    wrapper:
        "relative flex xxl:h-[946px] lg:px-[40px] px-[20px] pt-[32px] mlg:pt-[84px] lg:pt-[54px] xxl:pt-[124px] pb-[20px] w-full",
    insetWrapper:
        "section-wrapper h-[580px] md:h-[632px] mlg:h-[434px] lg:h-[608px] xxl:h-[854px] relative rounded-[12px] lg:rounded-[14px] border-2 lg:border-4 border-gold-400 shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] lg:shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] w-full",
    container: "relative w-full h-full flex items-center justify-center",
    overlay: "absolute inset-0 w-full z-[8] h-full pointer-events-none bannerOverlay rounded-[10px]",
    description: {
        richTextWrapper: "section-wrapper relative z-[9] flex items-center justify-center text-center w-full h-full",
        code: "hidden",
        link: "hidden",
        paragraph: "hidden",
        heading1: "specialHeading-1",
        heading2: "hidden",
        heading3: "hidden",
        heading4: "hidden",
        heading5: "specialHeading-3",
        heading6: "hidden",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "",
        list: "",
        title: "hidden",
        mainLink: "hidden",
    },
    contentWrapper: "hidden",
    bgWrapper: "absolute inset-0 w-full h-full",
    backgroundImage: "object-cover w-full h-full rounded-[10px]",
    cta: "hidden",
}

export const COCOA_LIFE = titleOnly
export const COOKIE_POLICY = titleOnly
export const PAGE_404 = titleOnly
