const { fragment: LinkFragment } = require("../UI/Link/Link.fragment")
const { fragment: ResponsiveImage } = require("../UI/ResponsiveImage/ResponsiveImage.fragment")
const query = (id, isPreview) => `
{
    imageWithText(id: "${id}", preview: ${isPreview}) {
        title
        subtitle
        contentHead
        contentBody
        image {
            ${ResponsiveImage}
        }
        cta {
            ${LinkFragment}
        }
        variant
    }
}
`
module.exports = { query }
