import React from "react"
import PropTypes from "prop-types"

function GoldUpArrow({ className }) {
    return (
        <svg
            className={className}
            width="35"
            height="24"
            viewBox="0 0 35 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_1469_6629)">
                <mask id="path-1-inside-1_1469_6629" fill="white">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M27.7266 21.2031L32.7148 16.2148L16.5 -1.19858e-06L0.285156 16.2148L5.27344 21.2031L16.5 9.97656L27.7266 21.2031Z"
                    />
                </mask>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.7266 21.2031L32.7148 16.2148L16.5 -1.19858e-06L0.285156 16.2148L5.27344 21.2031L16.5 9.97656L27.7266 21.2031Z"
                    fill="white"
                />
                <path
                    d="M32.7148 16.2148L34.1291 17.6291L35.5433 16.2148L34.1291 14.8006L32.7148 16.2148ZM27.7266 21.2031L26.3124 22.6173L27.7266 24.0316L29.1408 22.6173L27.7266 21.2031ZM16.5 -1.19858e-06L17.9142 -1.41421L16.5 -2.82843L15.0858 -1.41421L16.5 -1.19858e-06ZM0.285156 16.2148L-1.12906 14.8006L-2.54327 16.2148L-1.12906 17.6291L0.285156 16.2148ZM5.27344 21.2031L3.85922 22.6173L5.27344 24.0316L6.68765 22.6173L5.27344 21.2031ZM16.5 9.97656L17.9142 8.56235L16.5 7.14813L15.0858 8.56235L16.5 9.97656ZM31.3006 14.8006L26.3124 19.7889L29.1408 22.6173L34.1291 17.6291L31.3006 14.8006ZM15.0858 1.41421L31.3006 17.6291L34.1291 14.8006L17.9142 -1.41421L15.0858 1.41421ZM1.69937 17.6291L17.9142 1.41421L15.0858 -1.41421L-1.12906 14.8006L1.69937 17.6291ZM6.68765 19.7889L1.69937 14.8006L-1.12906 17.6291L3.85922 22.6173L6.68765 19.7889ZM6.68765 22.6173L17.9142 11.3908L15.0858 8.56235L3.85922 19.7889L6.68765 22.6173ZM15.0858 11.3908L26.3124 22.6173L29.1408 19.7889L17.9142 8.56235L15.0858 11.3908Z"
                    fill="#DEB36C"
                    mask="url(#path-1-inside-1_1469_6629)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_1469_6629"
                    x="0.285156"
                    y="0"
                    width="34.4297"
                    height="23.2031"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="2" dy="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1469_6629" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1469_6629" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default GoldUpArrow

GoldUpArrow.propTypes = {
    className: PropTypes.string,
}
