import React from "react"
import PropTypes from "prop-types"
import ProductCard from "../ProductCard/ProductCard"
import LoadMoreButton from "../UI/LoadMoreButton/LoadMoreButton"
import useFilteredProducts from "../../hooks/useFilteredProducts"

function ProductList(props) {
    const { style, selectedCategory, allProducts, loadMoreButtonLabel } = props
    const { productsToShow, setProductsToShow, filteredProducts, productsBySubcategory } = useFilteredProducts(
        allProducts,
        selectedCategory
    )
    const subcategoriesLength = Object.keys(productsBySubcategory).length
    const showLoadMoreButton = productsToShow < filteredProducts.length
    return (
        <div className={style.wrapper}>
            <div className={style.subcategoriesWrapper}>
                {Object.keys(productsBySubcategory).map(subcategoryKey => {
                    const subcategory = productsBySubcategory[subcategoryKey]
                    const renderSubcategoryTitle = subcategory.products.length > 0
                    return (
                        <div key={`plp_subcategory_${subcategoryKey}`} className={style.subcategoryWrapper}>
                            {subcategoriesLength > 1 ? (
                                <>
                                    {renderSubcategoryTitle && (
                                        <h3 id={`plp_subcategory_${subcategoryKey}`} className={style.subcategoryTitle}>
                                            {subcategory.label}
                                        </h3>
                                    )}
                                    <div className={style.items}>
                                        {subcategory.products.map(product => {
                                            return <ProductCard key={`plp_product_${product.id}`} product={product} />
                                        })}
                                    </div>
                                </>
                            ) : (
                                <div className={style.items}>
                                    {filteredProducts.slice(0, productsToShow).map(product => {
                                        return <ProductCard key={`plp_product_${product.id}`} product={product} />
                                    })}
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
            {subcategoriesLength === 1 && showLoadMoreButton && (
                <div className={style.loadMoreWrapper}>
                    <LoadMoreButton
                        label={loadMoreButtonLabel || "Loading more products"}
                        callback={() => setProductsToShow(productsToShow + 10)}
                    />
                </div>
            )}
        </div>
    )
}

export default ProductList

ProductList.propTypes = {
    selectedCategory: PropTypes.object,
    allProducts: PropTypes.array,
    loadMoreButtonLabel: PropTypes.string,
    style: PropTypes.object,
}
