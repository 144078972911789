import React from "react"
import PropTypes from "prop-types"
import * as styles from "./CocoaProfileBlockContent-tw-styles"
import RichTextModel from "../../RichTextModel/RichTextModel"
import YouTube from "react-youtube"

function CocoaProfileBlockContent(props) {
    const { title, description, youtubeVideoUrl } = props
    const style = styles.defaultStyle
    const validYoutubeUrl =
        youtubeVideoUrl && youtubeVideoUrl.match(/https?:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]{11}(&[^\s]*)?$/)
    const videoId = validYoutubeUrl && youtubeVideoUrl.match(/[\w-]{11}/)[0]

    return (
        <div className={style.blockContent}>
            {title && <h2 className={style.blockTitle}>{title}</h2>}
            {!!description && <RichTextModel richText={description} style={style.blockDescription} />}
            {videoId && (
                <div className={style.videoWrapper}>
                    <YouTube
                        videoId={videoId}
                        opts={{
                            width: "1920",
                            height: "1080",
                        }}
                        className="w-full h-full rounded-[12px] lg:rounded-[14px]"
                        iframeClassName="w-full h-full rounded-[12px] lg:rounded-[14px]"
                    />
                </div>
            )}
        </div>
    )
}

CocoaProfileBlockContent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.object,
    youtubeVideoUrl: PropTypes.string,
}

export default CocoaProfileBlockContent
