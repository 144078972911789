import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import CocoaProfileCard from "../UI/CocoaProfileCard/CocoaProfileCard"
import { PrevArrow, NextArrow } from "../UI/Carousel/Arrows"
import CocoaProfileBlockContent from "../UI/CocoaProfileBlock/CocoaProfileBlockContent"

const CocoaLifeCarousel = props => {
    const { style, title, itemsCollection } = props
    const { items } = itemsCollection || { items: [] }
    const sliderRef = useRef(null)
    const [activeBlock, setActiveBlock] = useState(items[0])
    const { card, ...activeBlockContent } = activeBlock || { card: {} }
    const [isChangingSlide, setIsChangingSlide] = useState(false)

    useEffect(() => {
        if (!window) return
        if (window.location.search) {
            const urlParams = new URLSearchParams(window.location.search)
            const profileId = urlParams.get("p")
            const foundBlock = items.find(item => item.card.profileId === profileId)
            if (foundBlock) {
                const foundIndex = items.indexOf(foundBlock)
                sliderRef?.current?.slickGoTo(foundIndex)
                setActiveBlock(foundBlock)
                setTimeout(() => {
                    const element = document.getElementById("cocoa-life-stories-carousel")
                    if (element) {
                        element.scrollIntoView({ behavior: "smooth" })
                    }
                }, 400)
            }
        }
    }, [])
    const settings = {
        className: `cocoa-life-carousel-slick item-count-${items.length}`,
        afterChange: current => {
            setActiveBlock(items[current])
            setIsChangingSlide(false)
        },
        beforeChange: () => {
            setIsChangingSlide(true)
        },
        slidesToShow: Math.min(4, items.length),
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        centerMode: true,
        variableWidth: true,
        focusOnSelect: true,
        speed: 0,
        centerPadding: "40px",
        responsive: [
            {
                breakpoint: 2559,
                settings: {
                    slidesToShow: Math.min(4, items.length),
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    variableWidth: true,
                    focusOnSelect: true,
                    speed: 0,
                    centerPadding: "0px",
                },
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    variableWidth: true,
                    focusOnSelect: true,
                    speed: 300,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    variableWidth: true,
                    focusOnSelect: true,
                    speed: 300,
                },
            },
        ],
    }
    const arrowLeftClickHandler = () => {
        sliderRef?.current?.slickPrev()
    }
    const arrowRightClickHandler = () => {
        sliderRef?.current?.slickNext()
    }
    return (
        Array.isArray(items) &&
        items.length > 0 && (
            <div className={style.wrapper} id="cocoa-life-stories-carousel">
                <div className={style.content}>
                    {!!title && <h2 className={style.title}>{title}</h2>}
                    <div className={style.sliderWrapper}>
                        <Slider {...settings} ref={sliderRef}>
                            {items?.map(item => (
                                <CocoaProfileCard
                                    key={`${item.sys.id}_slide_item`}
                                    isCardActive={item === activeBlock}
                                    onCardClick={() => setActiveBlock(item)}
                                    variant="cocoaLifeCarousel"
                                    className={isChangingSlide ? "pointer-events-none" : "cursor-pointer"}
                                    {...item.card}
                                />
                            ))}
                        </Slider>
                        <PrevArrow
                            buttonStyles={style.arrowPrev}
                            onClick={arrowLeftClickHandler}
                            disabled={isChangingSlide}
                        />
                        <NextArrow
                            buttonStyles={style.arrowNext}
                            onClick={arrowRightClickHandler}
                            disabled={isChangingSlide}
                        />
                    </div>
                    <CocoaProfileBlockContent {...activeBlockContent} />
                </div>
            </div>
        )
    )
}

CocoaLifeCarousel.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    itemsCollection: PropTypes.object,
}

export default CocoaLifeCarousel
