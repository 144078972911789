import React from "react"
import PropTypes from "prop-types"
import ProductList from "./ProductList"
import useProductListing from "../../hooks/useProductListing"
import { gaEventClasses } from "../../constants/gtm.constants"

const ProductListingSection = props => {
    const { style, title, products, filtersCollection, loadMoreButtonLabel } = props
    const { items: filters } = filtersCollection
    const [selectedCategory, handleCategoryChange] = useProductListing({ filters })

    return (
        <>
            <div className={style.titleWrapper}>
                <div className={style.titleSectionWrapper}>
                    <h2 className={style.title}>{title}</h2>
                </div>
            </div>
            <div className={style.filtersWrapper}>
                <div className={style.filtersSectionWrapper} id={"category-filters"}>
                    <div className={style.filtersContent}>
                        {filters.map(item => {
                            return (
                                <div key={`plp_filter_category_${item.id}`} className={style.filter}>
                                    <button
                                        id={`category_filter_button_${item.id}`}
                                        aria-selected={selectedCategory.id === item.id}
                                        onClick={() => {
                                            handleCategoryChange(item)
                                        }}
                                        className={`${style.filterButton} ${gaEventClasses?.button_click || ""}`}
                                        data-action-detail={`Product filter ${item.label}`}
                                    >
                                        {item.label}
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                    <div className={style.subcategoriesContent}>
                        {selectedCategory?.subcategoriesCollection?.items?.map(subcategory => {
                            return (
                                <div key={`plp_filter_subcategory_${subcategory.id}`}>
                                    <button
                                        id={`category_subcategory_button_${subcategory.id}`}
                                        onClick={() => {
                                            const subcategoryElement = document.getElementById(
                                                `plp_subcategory_${subcategory.id}`
                                            )
                                            if (subcategoryElement) {
                                                subcategoryElement.scrollIntoView({ behavior: "smooth" })
                                            }
                                        }}
                                        className={`${style.subcategoryButton} ${gaEventClasses?.button_click || ""}`}
                                        data-action-detail={`Product subcategory ${subcategory.label}`}
                                    >
                                        {subcategory.label}
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className={style.productListWrapper}>
                <div className={style.productListSectionWrapper}>
                    <ProductList
                        style={style.productList}
                        filters={filters}
                        selectedCategory={selectedCategory}
                        allProducts={products}
                        loadMoreButtonLabel={loadMoreButtonLabel}
                    />
                </div>
            </div>
        </>
    )
}

ProductListingSection.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    products: PropTypes.array,
    filtersCollection: PropTypes.object,
    loadMoreButtonLabel: PropTypes.string,
}

export default ProductListingSection
