const { fragment: LinkFragment } = require("../UI/Link/Link.fragment")
const { fragment: LinkCardFragment } = require("../UI/LinkCard/LinkCard.fragment")

const query = (id, isPreview) => `{
  instagramFeedSection(id: "${id}", preview: ${isPreview}) {
    internalTitle
    title
    postsCollection {
        items {
            ... on LinkCard {
                ${LinkCardFragment}
            }
        }
    }
    cta {
        ${LinkFragment}
    }
  }
}
`

module.exports = { query }
