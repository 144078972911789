export const defaultStyle = {
    wrapper: "relative",
    content: "section-wrapper",
}

export const WRAPPED = {
    wrapper: "relative",
    content: "section-wrapper",
}

export const COOKIES_SNIPPET = {
    wrapper: "relative bg-white mt-[-10px] pt-[10px]",
    content: "section-wrapper max-w-[1024px] py-8 cookies-snippet",
}

export const COOKIES_BUTTON_SNIPPET = {
    wrapper: "relative bg-white mt-[-10px] pt-[10px]",
    content: "section-wrapper max-w-[1024px] py-8",
}
