const { fragment: RichTextFragment } = require("../../RichTextModel/RichText.fragment")
const { fragment: CocoaProfileCardFragment } = require("../CocoaProfileCard/CocoaProfileCard.fragment")

const fragment = `
    sys {
        id
    }
    card {
        ${CocoaProfileCardFragment}
    }
    title
    description {
        ${RichTextFragment}
    }
    youtubeVideoUrl
`
module.exports = { fragment }
