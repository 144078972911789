const fragment = `
    internalTitle
    altText
    imageDesktopLarge {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
    }
    imageDesktop {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
    }
    imageTablet {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
    }
    imageMobile {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
    }
    largeDesktopBreakpoint
    tabletBreakpoint
    desktopBreakpoint
    customClass
    isAnimated
`
module.exports = { fragment }
