export const defaultStyle = {
    titleWrapper: "relative",
    titleSectionWrapper: "section-wrapper pt-[108px] pb-[24px] lg:pb-[62px] xxl:pb-[32px]",
    title: "specialHeading-1 text-center",
    filtersWrapper: "bg-transparent lg:bg-transparent-top-white-bottom-px xxl:bg-white mb-[-1px]",
    filtersSectionWrapper: "section-wrapper py-[18px] xxl:pb-[36px] flex flex-col gap-[16px]",
    filtersContent:
        "grid grid-cols-2 mlg:grid-flow-col mlg:auto-cols-fr gap-[16px] overflow-x-auto max-w-[354px] mx-auto mlg:mx-[unset] mlg:max-w-[unset] pb-[20px] mb-[-20px]",
    filter: "col-span-1 lg:col-span-2 flex items-center justify-center",
    filterButton: "w-full btn-secondary !border-[2px]",
    subcategoriesContent:
        "hidden mlg:flex flex-row gap-[16px] flex-nowrap w-full bg-gold-gradient rounded-[10px] shadow-[2px_2px_0_0_#000]",
    subcategoryButton: "btn-transparent",
    productListWrapper: "bg-white",
    productListSectionWrapper: "section-wrapper pb-[80px]",
    productList: {
        wrapper: "pt-[64px] lg:pt-[30px] pb-[16px] flex flex-col",
        subcategoriesWrapper: "w-full flex flex-col gap-[128px]",
        subcategoryWrapper: "w-full flex flex-col gap-[48px]",
        subcategoryTitle: "heading-2 text-black text-center lg:text-left",
        items: "grid grid-cols-2 gap-x-[16px] gap-y-[32px] mlg:gap-y-[48px] xxl:gap-y-[56px] md:grid-cols-3 mlg:grid-cols-4 xxl:grid-cols-6",
        loadMoreWrapper: "w-full mx-auto flex justify-center mt-[80px] xxl:mt-[120px]",
    },
}
