export const generateLayoutClasses = (mobileLayout, desktopLayout) => {
    let mobileClasses = ""
    let desktopClasses = ""

    switch (mobileLayout) {
        case "Mobile_Column_ImageTop_ContentBottom":
            mobileClasses = "flex flex-col"
            break
        case "Mobile_Column_ImageBottom_ContentTop":
            mobileClasses = "flex flex-col-reverse"
            break
        case "Mobile_Row_ImageLeft_ContentRight":
            mobileClasses = "flex flex-row"
            break
        case "Mobile_Row_ImageRight_ContentLeft":
            mobileClasses = "flex flex-row-reverse"
            break
        default:
            mobileClasses = "flex flex-col"
    }

    switch (desktopLayout) {
        case "Desktop_Row_ImageLeft_ContentRight":
            desktopClasses = "md:flex md:flex-row gap-4 [&_p]:pl-0 [&_h3]:pl-0"
            break
        case "Desktop_Row_ImageRight_ContentLeft":
            desktopClasses = "md:flex md:flex-row-reverse gap-4 [& p]:pr-0 [& h3]:pr-0"
            break
        case "Desktop_Column_ImageTop_ContentBottom":
            desktopClasses = "md:flex md:flex-col"
            break
        case "Desktop_Column_ImageBottom_ContentTop":
            desktopClasses = "md:flex md:flex-col-reverse"
            break
        default:
            desktopClasses = "md:flex md:flex-col"
    }

    return `w-full h-full ${mobileClasses} ${desktopClasses}`
}
