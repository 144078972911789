import React, { useState } from "react"
import PropTypes from "prop-types"
import Tabs from "./Tabs"
import Typography from "../UI/Typography/Typography"
import ResponsiveImage from "../UI/ResponsiveImage/ResponsiveImage"
import RichTextModel from "../RichTextModel/RichTextModel"
import { gaEventClasses } from "../../constants/gtm.constants"

const TabSection = props => {
    const { contentCollection, style } = props
    const items = contentCollection.items || []
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const activeTab = items[activeTabIndex]
    const goToNextTab = event => {
        const nextIndex = (activeTabIndex + 1) % items.length
        setActiveTabIndex(nextIndex)
    }
    if (!Array.isArray(items) || items.length === 0) {
        return null
    }
    return (
        <div className={style.tabWrapper}>
            <div className={style.tabElements}>
                {items.map((data, index) => {
                    return (
                        <Tabs
                            key={`tab_${data.sys.id}`}
                            {...data}
                            isActive={activeTabIndex === index}
                            onClick={() => setActiveTabIndex(index)}
                            style={style}
                        />
                    )
                })}
            </div>
            {activeTab && (
                <>
                    <div key={activeTab.id} className={style.contentContainer}>
                        <div className={style.titleWrapper}>
                            {activeTab?.contentSubtitle && (
                                <Typography className={style.contentSubtitle} content={activeTab.contentSubtitle} />
                            )}
                            {activeTab?.contentTitle && (
                                <Typography className={style.contentTitle} content={activeTab.contentTitle} />
                            )}
                        </div>
                        <div className={style.contentWrapper}>
                            {!!activeTab?.contentMedia && (
                                <div className={style.contentImgWrapper}>
                                    <ResponsiveImage
                                        contentfulImagesComponent={activeTab.contentMedia}
                                        className={style.contentImg}
                                    />
                                </div>
                            )}
                            <div className={style.textContent}>
                                {activeTab?.contentCopyColumn1 && (
                                    <RichTextModel
                                        richText={activeTab.contentCopyColumn1}
                                        style={style.contentCopyColumn1}
                                    />
                                )}
                                {activeTab?.contentCopyColumn2 && (
                                    <RichTextModel
                                        richText={activeTab.contentCopyColumn2}
                                        style={style.contentCopyColumn2}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {!!activeTab.nextTabButtonLabel && (
                        <div className={style.buttonWrapper}>
                            <button
                                className={`${style.bottomButton} ${gaEventClasses?.button_click || ""}`}
                                data-action-detail={`History Next Tab: ${activeTab.nextTabButtonLabel}`}
                                onClick={goToNextTab}
                            >
                                {activeTab.nextTabButtonLabel}
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

TabSection.propTypes = {
    contentCollection: PropTypes.object,
    style: PropTypes.object,
}

export default TabSection
