import React from "react"
import PropTypes from "prop-types"
import RichTextModel from "../RichTextModel/RichTextModel"
import Link from "../UI/Link/Link"
import useScreenSize from "../../hooks/useScreenSize"
import CocoaProfileLink from "../UI/CocoaProfileLink/CocoaProfileLink"
import Slider from "react-slick"

const CocoaLifeSectionMobile = props => {
    const { style, icon, description, cta, cocoaProfileLinksCollection } = props
    const settings = {
        className: "cocoa-life-section-slick",
        responsive: [
            {
                breakpoint: 9999,
                settings: "unslick",
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    variableWidth: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    variableWidth: true,
                },
            },
        ],
    }

    return (
        <div className={style.wrapper}>
            <div className={style.firstCardWrapper}>
                <img src={icon.url} alt={icon.title} className={style.icon} />
                {!!description && <RichTextModel richText={description} style={style.description} />}
            </div>
            <div className={style.sliderWrapper}>
                <Slider {...settings}>
                    {cocoaProfileLinksCollection?.items?.map(item => (
                        <CocoaProfileLink key={`${item.sys.id}_profile_slide_item`} {...item} />
                    ))}
                </Slider>
            </div>
            {!!cta && <Link {...cta} className={style.cta} />}
        </div>
    )
}
const CocoaLifeSectionDesktop = props => {
    const { style, icon, description, cta, cocoaProfileLinksCollection, isLargeDesktop } = props
    return (
        <div className={style.wrapper}>
            <div className={style.firstCardWrapper}>
                <img src={icon.url} alt={icon.title} className={style.icon} loading="lazy" />
                {!!description && <RichTextModel richText={description} style={style.description} />}
                {!!cta && <Link {...cta} className={style.cta} />}
            </div>
            {cocoaProfileLinksCollection?.items?.[0] && (
                <CocoaProfileLink {...cocoaProfileLinksCollection?.items?.[0]} variant="cocoaLifeSection" />
            )}
            {cocoaProfileLinksCollection?.items?.[1] && (
                <CocoaProfileLink {...cocoaProfileLinksCollection?.items?.[1]} variant="cocoaLifeSection" />
            )}
            {cocoaProfileLinksCollection?.items?.[2] && (
                <CocoaProfileLink {...cocoaProfileLinksCollection?.items?.[2]} variant="cocoaLifeSection" />
            )}
            {isLargeDesktop && cocoaProfileLinksCollection?.items?.[3] && (
                <CocoaProfileLink {...cocoaProfileLinksCollection?.items?.[3]} variant="cocoaLifeSection" />
            )}
        </div>
    )
}

const CocoaLifeSection = props => {
    const { style, title, ...rest } = props
    const screenSize = useScreenSize()

    return (
        <div className={style.wrapper}>
            <div className={style.content}>
                {title && <h2 className={style.title}>{title}</h2>}
                {screenSize?.isMobileOrTablet ? (
                    <CocoaLifeSectionMobile {...rest} style={style.containerMobile} />
                ) : (
                    <CocoaLifeSectionDesktop
                        {...rest}
                        style={style.containerDesktop}
                        isLargeDesktop={screenSize?.isDesktopAndLargeDesktop}
                    />
                )}
            </div>
        </div>
    )
}

const ViewPropTypes = {
    style: PropTypes.object,
    icon: PropTypes.object,
    description: PropTypes.object,
    cta: PropTypes.object,
    cocoaProfileLinksCollection: PropTypes.object,
}

CocoaLifeSectionMobile.propTypes = ViewPropTypes
CocoaLifeSectionDesktop.propTypes = { ...ViewPropTypes, isLargeDesktop: PropTypes.bool }
CocoaLifeSection.propTypes = { ...ViewPropTypes, title: PropTypes.string }

export default CocoaLifeSection
