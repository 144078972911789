export const defaultStyle = {
    wrapper: "section-wrapper relative pt-[80px] pb-[128px]",
    titleWrapper: "text-center mb-[36px] lg:mb-[56px] xxl:mb-[128px]",
    heading3: "heading-3 -gradient-text mb-[24px] lg:mb-[32px]",
    heading2: "heading-2 text-white",
    image: "mb-[36px] lg:mb-[56px] xxl:mb-0 rounded-lg w-full",
    contentHead: "heading-4 text-white mb-[36px]",
    contentBody: "body-2 text-white",
    linkWrapper: "flex justify-center align-center mt-[36px] lg:mt-[128px]",
    link: "btn-primary",
}
export const HISTORY_OF_CHOCOLATE = {
    gridWrapper: "lg:grid lg:grid-cols-12 xxl:gap-[56px] xxl:items-start",
    imageWrapper: "lg:col-span-12 xxl:col-span-6",
    textWrapper: "lg:grid lg:grid-cols-12 lg:gap-[28px] xxl:gap-0 lg:col-span-12 xxl:col-span-6",
    typogaphy: "lg:col-span-6 xxl:col-span-12",
}
