import React from "react"
import PropTypes from "prop-types"
import { SECTION as style } from "./HistoryCarousel-tw-styles"
import Link from "../UI/Link/Link"
import HistoryCarouselBlocks from "./HistoryCarouselBlocks"

const HistoryCarousel = props => {
    const { title, linksCollection, periodsCollection } = props

  if(!periodsCollection && !Array.isArray(periodsCollection?.items) && periodsCollection?.items?.length === 0)
    return null

    return (
        <div className={style.wrapper}>
          {title && <h1 className={style.title}>{title}</h1>}
          <HistoryCarouselBlocks periods={periodsCollection.items}/>

          {linksCollection && Array.isArray(linksCollection?.items) && linksCollection?.items?.length > 0 &&
            <div className={style.buttons}>
              {linksCollection.items.map((item, i) =>(
                <Link {...item} key={`${i}-${item.sys.id}`} className={style.button}/>
              ))}
            </div>
          }
        </div>
    )
}

HistoryCarousel.propTypes = {
  title: PropTypes.string,
  linksCollection: PropTypes.shape(),
  periodsCollection: PropTypes.shape(),
}

export default HistoryCarousel
