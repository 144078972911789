const { fragment: ResponsiveImageFragment } = require("../ResponsiveImage/ResponsiveImage.fragment")

const fragment = `
    sys {
        id
    }
    profileId
    title
    backgroundImage {
        ${ResponsiveImageFragment}
    }
    foregroundImage {
        ${ResponsiveImageFragment}
    }
`
module.exports = { fragment }
