const { fragment: ImageHeroBannerFragment } = require("../ImageHeroBanner/ImageHeroBanner.fragment")

const query = (id, isPreview) => `{
  heroSlider(id: "${id}", preview: ${isPreview}) {
    internalTitle
    variant
    slidesCollection(limit: 10) {
      items {
        ${ImageHeroBannerFragment}
      }
    }
  }
}
`

module.exports = { query }
