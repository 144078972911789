const { fragment: ResponsiveImageFragment } = require("../../UI/ResponsiveImage/ResponsiveImage.fragment")
const { fragment: LinkFragment } = require("../../UI/Link/Link.fragment")
const { fragment: FooterLinksContainerFragment } = require("./FooterLinksContainer.fragment")
const { fragment: NewsletterSectionFragment } = require("../../NewsletterSection/NewsletterSection.fragment")

const query = (isPreview, locale) => `{
  footerCollection(preview: ${isPreview}, limit: 1, locale: "${locale}") {
    items {
      internalTitle
      brandLogoImage {
        ${ResponsiveImageFragment}
      }
      brandLogoLink {
        ${LinkFragment}
      }
      copyright
      mainLinksCollection {
        items {
            ... on FooterLinksContainer {
                ${FooterLinksContainerFragment}
            }
        }
      }
      bottomLinksCollection {
        items {
            ... on FooterLinksContainer {
                ${FooterLinksContainerFragment}
            }
        }
      }
      newsletterSection {
        ... on NewsletterSection {
          ${NewsletterSectionFragment}
        }
      }
    }
  }
}
`
module.exports = { query }
