import { useEffect, useRef, useCallback } from "react"

const useInfiniteScroll = callback => {
    const contentRef = useRef(null)
    const timerRef = useRef(null)

    const memoizedCallback = useCallback(() => {
        callback()
    }, [callback])

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        timerRef.current = setTimeout(() => {
                            memoizedCallback()
                        }, 2000)
                    } else {
                        clearTimeout(timerRef.current)
                    }
                })
            },
            { threshold: 0.1 }
        )

        if (contentRef.current) {
            observer.observe(contentRef.current)
        }

        return () => {
            if (contentRef.current) {
                observer.unobserve(contentRef.current)
            }
            clearTimeout(timerRef.current)
        }
    }, [memoizedCallback])

    return contentRef
}

export default useInfiniteScroll
