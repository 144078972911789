import { useState, useEffect } from "react"

const useFilteredProducts = (allProducts, selectedCategory) => {
    const [productsToShow, setProductsToShow] = useState(20)
    const [filteredProducts, setFilteredProducts] = useState([])
    const [productsBySubcategory, setProductsBySubcategory] = useState({})

    useEffect(() => {
        const availableSubcategories = selectedCategory?.subcategoriesCollection?.items || []
        const filtered = allProducts.filter(product =>
            product.portfolios.some(portfolio => String(portfolio.portfolio_id) === String(selectedCategory?.id))
        )
        setFilteredProducts(filtered)

        const subcategories = {}
        availableSubcategories.forEach(subcategory => {
            subcategories[subcategory.id] = {
                label: subcategory.label,
                products: [],
            }
        })
        subcategories["Other"] = {
            label: "Other",
            products: [],
        }

        filtered.forEach(product => {
            let added = false
            for (let i = 0; i < product.portfolios.length; i++) {
                const portfolioId = String(product.portfolios[i].portfolio_id)
                if (subcategories[portfolioId]) {
                    subcategories[portfolioId].products.push(product)
                    added = true
                    break
                }
            }
            if (!added) {
                subcategories["Other"].products.push(product)
            }
        })

        setProductsBySubcategory(subcategories)
    }, [allProducts, selectedCategory])

    return { productsToShow, setProductsToShow, filteredProducts, productsBySubcategory }
}

export default useFilteredProducts
