export const defaultStyle = {
    wrapper: "relative bg-white-top-red-gradient-bottom",
    content:
        "section-wrapper flex flex-col pt-[40px] pb-[62px] lg:pt-[64px] md:pb-[80px] xxl:pt-[120px] xxl:pb-[240px]",
    title: "heading-2 text-green text-center",
    sliderWrapper:
        "relative mt-[32px] mb-[40px] lg:mt-[80px] lg:mb-[42px] xxl:mb-[128px] mx-[-20px] md:mx-[-16px] lg:mx-[-32px] xxl:mx-[-40px]",
    arrowPrev:
        "absolute hidden mlg:block left-[20px] top-[110%] lg:[&_svg]:w-[21px] lg:[&_svg]:h-[32px] xxl:[&_svg]:w-[31px] xxl:[&_svg]:h-[44px]",
    arrowNext:
        "absolute hidden mlg:block right-[20px] top-[110%] lg:[&_svg]:w-[21px] lg:[&_svg]:h-[32px] xxl:[&_svg]:w-[31px] xxl:[&_svg]:h-[44px]",
}
