import React from "react"
import PropTypes from "prop-types"
import ResponsiveImage from "../UI/ResponsiveImage/ResponsiveImage"
import RichTextModel from "../RichTextModel/RichTextModel"
import * as styles from "./ImageHeroBanner-tw-styles"
import Link from "../UI/Link/Link"

const ImageHeroBanner = props => {
    const { image, backgroundImage, description, cta, sectionId, variant } = props
    const style = styles[variant] || styles.defaultStyle
    return (
        <div className={style?.wrapper} id={sectionId}>
            <div className={style.insetWrapper}>
                <div className={style.bgWrapper}>
                    {!!backgroundImage && (
                        <ResponsiveImage
                            isAnimated={backgroundImage?.isAnimated}
                            contentfulImagesComponent={backgroundImage}
                            className={style.backgroundImage}
                        />
                    )}
                    <div className={style.overlay}></div>
                </div>
                <div className={style.container}>
                    <div className={style.description.richTextWrapper}>
                        <RichTextModel richText={description} style={style.description} />
                        {!!cta && <Link {...cta} className={style.cta} />}
                    </div>
                    <div className={style.contentWrapper}>
                        {!!image &&
                            <ResponsiveImage
                                isAnimated={image?.isAnimated}
                                contentfulImagesComponent={image}
                                className={style.image}
                            />}
                    </div>
                </div>
            </div>
        </div>
    )
}

ImageHeroBanner.propTypes = {
    image: PropTypes.object,
    backgroundImage: PropTypes.object,
    description: PropTypes.object,
    cta: PropTypes.object,
    sectionId: PropTypes.string,
    variant: PropTypes.string,
}

export default ImageHeroBanner
