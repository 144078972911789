import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import ImageHeroBanner from "../../ImageHeroBanner/ImageHeroBanner"
import { NextArrow, PrevArrow } from "../../UI/Carousel/Arrows"
import clsx from "clsx"
import { gaEventClasses, gaEventLabels } from "../../../constants/gtm.constants"

const HomeHeroSlider = props => {
    const { style, slidesCollection } = props
    const slides = slidesCollection?.items || []

    const progressDot = useRef()
    const [currentSlide, setCurrentSlide] = useState(0)

    const changeProgressDotWidth = index => {
        if (progressDot.current !== undefined) {
            const calc = 20 * index
            const newWidth = `${14 + calc}px`
            progressDot.current.style.width = newWidth
        }
    }

    useEffect(() => {
        changeProgressDotWidth(currentSlide)
    }, [currentSlide])

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <PrevArrow buttonStyles={style.prevArrow} />,
        nextArrow: <NextArrow buttonStyles={style.nextArrow} />,
        responsive: [
            {
                breakpoint: 1439,
                settings: {
                    dots: true,
                    arrows: false,
                    beforeChange: (oldIndex, newIndex) => {
                        setCurrentSlide(newIndex)
                    },
                    appendDots: dots => customDots(dots),
                    customPaging: () => (
                        <button
                            className={clsx("absolute w-[14px] h-[14px]", gaEventClasses.slider)}
                            data-action-detail={gaEventLabels.dots}
                        ></button>
                    ),
                },
            },
        ],
    }

    const customDots = dots => (
        <div>
            <div className={style?.mainWrapperProgressDots}>
                <div ref={progressDot} style={{ width: "14px" }} className={style?.barWrapper}></div>
                <ul className={style?.progressWrapper}>
                    {dots.map((item, index) => {
                        const childrenWithAlt = React.cloneElement(item.props.children, {
                            "aria-label": `Dot ${index}`,
                        })

                        return (
                            <li
                                data-product-carousel-dot-index={index}
                                className={style?.progressDot}
                                onClick={() => {
                                    changeProgressDotWidth(index)
                                }}
                                key={`dot-${index}`}
                            >
                                {childrenWithAlt}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )

    return (
        <div className={style?.wrapper}>
            {slides.length > 1 ? (
                <Slider {...sliderSettings}>
                    {slides.map((slide, index) => (
                        <div key={`${slide.sys?.id}_${index}`} className={style?.contentWrapper}>
                            <ImageHeroBanner {...slide} />
                        </div>
                    ))}
                </Slider>
            ) : (
                <div className={style?.contentWrapper}>
                    <ImageHeroBanner {...slides[0]} />
                </div>
            )}
        </div>
    )
}

HomeHeroSlider.propTypes = {
    style: PropTypes.object,
    slidesCollection: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape(ImageHeroBanner.propTypes)),
    }),
}

export default HomeHeroSlider
