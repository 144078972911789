import React, { useState } from "react"
import PropTypes from "prop-types"
import useRecipeSearchResults from "../../hooks/useRecipeSearchResults"
import RecipeCard from "../RecipeCard/RecipeCard"
import { useGlobalContext } from "../../context/GlobalContext"
import LoadMoreButton from "../UI/LoadMoreButton/LoadMoreButton"

function RecipeList(props) {
    const { language, defaultCategory, loadMoreButtonLabel, noRecipesFoundLabel, style } = props

    const [hitsPerPage, setHitsPerPage] = useState(12)
    const { results, error } = useRecipeSearchResults({
        language: language,
        defaultCategory: defaultCategory,
        hitsPerPage: hitsPerPage,
    })
    const { labels } = useGlobalContext()
    const { minutesLabel = "minutes" } = labels?.rdp

    if (error) {
        return <div className={style.error}>Error fetching recipes</div>
    }
    return (
        <>
            <div className={style.grid}>
                {results &&
                    results?.hits.map(recipe => {
                        return (
                            <div key={`rlp_recipe_card_${recipe.id}`} className={style.cardWrapper}>
                                <RecipeCard recipe={recipe} minutesLabel={minutesLabel} />
                            </div>
                        )
                    })}
            </div>
            {results?.nbHits === 0 && (
                <div className={style.notFound}>
                    <span>{noRecipesFoundLabel || "No recipes found..."}</span>
                </div>
            )}
            {hitsPerPage < results?.nbHits && (
                <div className={style.loadMoreWrapper}>
                    <LoadMoreButton
                        label={loadMoreButtonLabel || "Loading more recipes"}
                        callback={() => setHitsPerPage(hitsPerPage + 8)}
                    />
                </div>
            )}
        </>
    )
}

export default RecipeList

RecipeList.propTypes = {
    language: PropTypes.oneOf(["nl-BE", "fr-BE", "nl-NL"]),
    defaultCategory: PropTypes.string,
    noRecipesFoundLabel: PropTypes.string,
    loadMoreButtonLabel: PropTypes.string,
    style: PropTypes.object,
}
