export const defaultStyle = {
    wrapper: "relative",
}

export const HOME_HERO_SLIDER = {
    wrapper:
        "hero-slider relative max-w-[2560px] mx-auto xxl:min-h-[918px] mt-[-55px] md:mt-[-35px] mlg:mt-[44px] pb-[14px] mlg:pb-[24px] xxl:pb-[30px] mlg:min-h-[480px] lg:min-h-[580px]",
    contentWrapper: "flex w-full h-full justify-center items-center",
    mainWrapperProgressDots: "inline-block relative",
    barWrapper:
        "absolute ml-[13px] mx-[3px] my-[8px] rounded-[20px] gold-gradient h-[14px] z-[11] transition-[width] ease-in-out duration-300",
    progressWrapper: "flex px-[10px] items-center justify-center rounded-[20px] z-[1]",
    progressDot: "relative w-[14px] h-[14px] mx-[3px] my-[8px] rounded-full bg-white z-10",
    prevArrow:
        "z-[8] absolute bottom-0 translate-y-1/2 left-1/2 translate-x-[-44px] xxl:translate-x-[-189px] [&_svg]:w-[21px] [&_svg]:h-[32px] xxl:[&_svg]:w-[31px] xxl:[&_svg]:h-[47px]",
    nextArrow:
        "z-[8] absolute bottom-0 translate-y-1/2 left-1/2 translate-x-[44px]  xxl:translate-x-[189px] [&_svg]:w-[21px] [&_svg]:h-[32px] xxl:[&_svg]:w-[31px] xxl:[&_svg]:h-[47px]",
}
