import React from "react"
import PropTypes from "prop-types"
import CocoaProfileCard from "../CocoaProfileCard/CocoaProfileCard"
import Link from "../Link/Link"

function CocoaProfileLink(props) {
    const { card, link, variant } = props

    return (
        <Link {...link} className="flex flex-row lg:flex-grow mlg:w-full">
            <CocoaProfileCard {...card} variant={variant} />
        </Link>
    )
}

CocoaProfileLink.propTypes = {
    card: PropTypes.object,
    link: PropTypes.object,
    variant: PropTypes.string,
}

export default CocoaProfileLink
