export const defaultStyle = {
    wrapper: "relative",
    container:
        "relative section-wrapper flex flex-col items-center justify-start gap-[16px] mlg:gap-[32px] py-[64px] xxl:py-[120px]",
    title: "specialHeading-2 text-center mb-[26px] lg:mb-[48px] xxl:mb-[96px]",
    postsWrapper: "relative w-screen mlg:w-full mx-[-16px] lg:mx-[0]",
    arrowLeft: "absolute top-1/2 left-[20px] -translate-y-1/2 z-[9]",
    arrowRight: "absolute top-1/2 right-[20px] -translate-y-1/2 z-[9]",
    cta: "btn-primary",
}
