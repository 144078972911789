export const defaultStyle = {
    wrapper: "hidden",
}

export const CHOCOLATES_GRID = {
    wrapper: "relative pt-[36px] lg:pt-[77px] pb-[64px] xxl:pb-[120px]",
    contentWrapper: "section-wrapper relative",
    title: "text-center specialHeading-1 mb-10 md:mb-8 lg:mb-20 xxl:mb-[128px]",
    gridWrapper: "grid grid-cols-12 gap-4",
}

export const NOTRE_VISION_GRID = {
    wrapper: "bg-white relative pt-[36px] lg:pt-[77px] pb-[64px] xxl:pb-[120px]",
    contentWrapper: "section-wrapper relative",
    title: "text-center heading-2 text-green mb-[16px]",
    subtitle: "text-center heading-4 font-bold mb-[26px] xxl:mb-[80px]",
    gridWrapper: "flex flex-col md:flex-row gap-[32px] md:gap-[16px] justify-center items-center md:items-start",
}
export const NOS_PRINCIPES_GRID = {
    wrapper: "bg-white relative pt-[36px] lg:pt-[77px] pb-[64px] xxl:pb-[120px]",
    contentWrapper: "section-wrapper relative",
    title: "text-center heading-2 text-green mb-[32px] lg:mb-[80px]",
    subtitle: "text-center heading-4 font-bold mb-[26px]",
    gridWrapper: "grid grid-rows-6 md:grid-rows-3 md:grid-flow-col xxl:grid-rows-2 gap-4 justify-center",
}
