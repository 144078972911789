const { fragment: ResponsiveImageFragment } = require("../UI/ResponsiveImage/ResponsiveImage.fragment")
const { fragment: RichTextFragment } = require("../RichTextModel/RichText.fragment")

const fragment = `
    image {
        ${ResponsiveImageFragment}
    }
    backgroundImage {
        ${ResponsiveImageFragment}
    }
    description{
        ${RichTextFragment}
    }
    cta {
        url
        label
        title
        target
        scrollToElementId
        gaEventClass
        gaEventLabel
    }
    sectionId
    variant
`
module.exports = { fragment }
