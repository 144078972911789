const algoliasearch = require("algoliasearch")
const {
    APP_ID,
    SEARCH_KEY,
    INDEX_RECIPE_NL_BE,
    INDEX_RECIPE_FR_BE,
    INDEX_RECIPE_NL_NL,
    INDEX_SEARCH_NL_BE,
    INDEX_SEARCH_FR_BE,
    INDEX_SEARCH_NL_NL,
} = require("../config/algoliaConfig")

class AlgoliaService {
    constructor(appId, apiKey) {
        this.client = algoliasearch(appId, apiKey)
        this.indexes = {
            "recipe-nl-BE": this.client.initIndex(INDEX_RECIPE_NL_BE),
            "recipe-fr-BE": this.client.initIndex(INDEX_RECIPE_FR_BE),
            "recipe-nl-NL": this.client.initIndex(INDEX_RECIPE_NL_NL),
            "search-nl-BE": this.client.initIndex(INDEX_SEARCH_NL_BE),
            "search-fr-BE": this.client.initIndex(INDEX_SEARCH_FR_BE),
            "search-nl-NL": this.client.initIndex(INDEX_SEARCH_NL_NL),
        }
    }

    getIndex(category, language) {
        const key = `${category}-${language}`
        if (!this.indexes[key]) {
            throw new Error(`Index not found for category "${category}" and language "${language}"`)
        }
        return this.indexes[key]
    }

    async getSearchResults(category, language, query, options = {}) {
        try {
            const index = this.getIndex(category, language)
            const results = await index.search(query, options)
            return results
        } catch (error) {
            console.error("Error fetching search results:", error)
            throw error
        }
    }
}

const algoliaSearchInstance = new AlgoliaService(APP_ID, SEARCH_KEY)
module.exports = algoliaSearchInstance
