const query = (id, isPreview) => `
{
    searchResultsContainer(id: "${id}", preview: ${isPreview}) {
        internalTitle
        title
        searchPlaceholder
        searchLabel
        results
        resultsPlural
        noResults
        loadingLabel
        language
        loadMoreLabel
    }
}
`
module.exports = { query }
