export const defaultStyle = {
    wrapper: "relative overflow-hidden h-[220px] lg:h-[400px] xxl:h-[503px] rounded-lg group",
    imgWrapper: "",
    img: "absolute inset-0 object-cover object-center w-full h-full rounded-lg transition-transform duration-300 ease-in-out group-hover:scale-125",
    content: "absolute p-4 inset-0 flex items-center justify-center",
    link: "image-text text-white text-center drop-shadow-[2px_2px_#000] md:drop-shadow-[3px_3px_#000] lg:drop-shadow-[4px_4px_#000] tracking-[0.3em] uppercase",
}
export const TEXT_CENTERED = defaultStyle
export const INSTAGRAM_POST = {
    wrapper:
        "relative overflow-hidden block mx-[8px] p-[12px] max-w-full w-full h-[369px] sm:h-[407px] mlg:h-[290px] lg:h-[400px]  xxl:h-[634px] border-2 border-gold-500 rounded-[10px] group hover:border-4",
    imgWrapper: "relative w-full h-full overflow-hidden rounded-[10px]",
    img: "absolute h-full object-cover object-center transition-transform duration-300 ease-in-out group-hover:scale-125 w-full",
    content: "hidden",
    link: "hidden",
}
export const RECIPE_CARD = {
    wrapper:
        "relative overflow-hidden block mx-[10px] max-w-full w-[320px] h-[200px] sm:h-[220px] sm:w-[352px] mlg:min-w-[28.5vw] mlg:w-full lg:w-[31vw] lg:h-[20vw] xxl:w-[815px] xxl:h-[550px] rounded-[10px] group",
    imgWrapper: "relative w-full h-full overflow-hidden rounded-[10px]",
    img: "absolute h-full w-full object-cover object-center transition-transform duration-300 ease-in-out group-hover:scale-125 rounded-[10px] ",
    content: "absolute p-4 inset-0 flex items-center justify-center ",
    link: "body-1 text-white text-center drop-shadow-[2px_2px_#000]",
}
export const TEXT_CENTERED_SHADOW_HOVER = {
    wrapper:
        "relative overflow-hidden h-[220px] mlg:h-[400px] xxl:h-[503px] rounded-lg group hover:shadow-[4px_4px_0px_#000000]",
    imgWrapper: "",
    img: "absolute inset-0 object-cover object-center w-full h-full rounded-lg  ",
    foregroundImage:
        "absolute top-0 left-0 w-full h-full object-cover overflow-hidden transition-transform duration-300 ease-in-out group-hover:scale-125",
    content: "absolute p-4 inset-0 flex items-center justify-center",
    link: "image-text text-white text-center drop-shadow-[2px_2px_#000] md:drop-shadow-[3px_3px_#000] lg:drop-shadow-[4px_4px_#000] tracking-[0.3em] uppercase",
}
export const TEXT_CENTERED_SHADOW_HOVER_WEBP = {
    wrapper:
        "relative overflow-hidden h-[220px] mlg:h-[400px] xxl:h-[503px] rounded-lg group hover:shadow-[4px_4px_0px_#000000]",
    imgWrapper: "",
    img: "absolute z-[8] inset-0 object-cover object-center w-full h-full rounded-lg group-hover:hidden ",
    foregroundImage:
        "absolute top-0 left-0 w-full h-full object-cover overflow-hidden transition-transform duration-300 ease-in-out  group-hover:scale-125",
    content: "absolute p-4 inset-0 flex items-center justify-center z-[9]",
    link: "image-text text-white text-center drop-shadow-[2px_2px_#000] md:drop-shadow-[3px_3px_#000] lg:drop-shadow-[4px_4px_#000] tracking-[0.3em] uppercase",
}
