const fragment = `
    sys {
        id
    }
    url
    label
    title
    scrollToElementId
    gaEventClass
    gaEventLabel
`
module.exports = { fragment }
