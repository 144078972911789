export const cocoaLifeSection = {
    wrapper:
        "relative gold-gradient shadow-[4px_4px_0px_#000000] rounded-[10px] overflow-hidden py-[32px] flex flex-col h-full min-h-[476px] mlg:min-h-[461px] lg:min-h-[500px] xxl:min-h-[649px] group min-w-[310px] sm:min-w-[353px] md:min-w-[340px] lg:flex-grow lg:w-full mlg:min-w-[220px] mlg:w-full lg:min-w-[332px] lg:max-w-[588px]",
    wrapperActive:
        "relative border-[3px] bg-white border-gold-300 shadow-[4px_4px_0px_#000000] rounded-[10px] overflow-hidden py-[32px] flex flex-col h-full min-h-[476px] lg:min-h-[500px] xxl:min-h-[649px] group min-w-[310px] sm:w-[353px] md:min-w-[340px] lg:flex-grow lg:w-full mlg:min-w-[220px] mlg:w-full lg:min-w-[332px] lg:max-w-[588px]",
    title: "relative heading-3 text-center",
    images: "relative flex-1",
    backgroundImageWrapper:
        "select-none absolute bottom-0 left-0 right-0 flex justify-center items-bottom transition-all duration-300 ease-in-out group-hover:scale-105 origin-bottom",
    backgroundImage: "max-w-[280px] mlg:max-w-[20vw] lg:max-w-[304px] xxl:max-w-[390px]",
    foregroundImageWrapper:
        "select-none absolute bottom-0 left-0 right-0 flex justify-center items-bottom transition-all duration-300 ease-in-out group-hover:scale-105 origin-bottom",
    foregroundImage: "max-w-[280px] mlg:max-w-[20vw] lg:max-w-[304px] xxl:max-w-[390px]",
}

export const cocoaLifeCarousel = {
    wrapper:
        "relative border-transparent gold-gradient shadow-[4px_4px_0px_#000000] rounded-[10px] overflow-hidden py-[32px] flex flex-col h-full min-h-[476px] lg:min-h-[500px] xxl:min-h-[649px] group w-full min-w-[310px] sm:min-w-[350px] md:min-w-[340px] mlg:min-w-[240px] mlg:w-full lg:min-w-[330px] xxl:min-w-[605px]",
    wrapperActive:
        "relative border-[3px] bg-white border-gold-300 shadow-[4px_4px_0px_#000000] rounded-[10px] overflow-hidden py-[32px] flex flex-col h-full min-h-[476px] lg:min-h-[500px] xxl:min-h-[649px] group min-w-[310px] sm:min-w-[350px] md:min-w-[340px] mlg:min-w-[230px] lg:min-w-[330px] xxl:min-w-[605px]",
    title: "relative heading-3 text-center",
    images: "relative flex-1",
    backgroundImageWrapper:
        "select-none absolute bottom-[0%] left-0 right-0 flex justify-center items-bottom transition-all duration-300 ease-in-out group-hover:scale-105 origin-bottom",
    backgroundImage: "max-w-[324px] mlg:max-w-[20vw] lg:max-w-[304px] xxl:max-w-[unset] xxl:w-full",
    foregroundImageWrapper:
        "select-none absolute bottom-[0%] left-0 right-0 flex justify-center items-bottom transition-all duration-300 ease-in-out group-hover:scale-105 origin-bottom",
    foregroundImage: "max-w-[285px] mlg:max-w-[20vw] lg:max-w-[304px] xxl:max-w-[unset] xxl:w-full",
}
