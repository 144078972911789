import React, { useState } from "react"
import PropTypes from "prop-types"
import SearchIcon from "../../assets/icons/SearchIcon"
import useSearch from "../../hooks/useSearch"
import useSearchResults from "../../hooks/useSearchResults"
import Link from "../UI/Link/Link"
import LoadMoreButton from "../UI/LoadMoreButton/LoadMoreButton"

const getResultsTitle = ({
    searchTerm,
    resultsCount,
    noResults,
    results,
    resultsPlural,
    loadingLabel,
    loading,
    style,
}) => {
    if (loading) {
        return <h2 className={style.resultsTitleCentered}>{loadingLabel}</h2>
    }
    if (resultsCount === 0) {
        const _noResults = noResults.replace("{{term}}", searchTerm)
        return <h2 className={style.resultsTitleCentered}>{_noResults}</h2>
    }
    const _results = resultsCount === 1 ? results : resultsPlural
    const _resultsTitle = _results.replace("{{count}}", resultsCount).replace("{{term}}", searchTerm)
    return <h2 className={style.resultsTitle}>{_resultsTitle}</h2>
}

const SearchResultsContainer = props => {
    const {
        style,
        title,
        language,
        searchPlaceholder: _searchPlaceholder,
        searchLabel: _searchLabel,
        results: _results,
        resultsPlural: _resultsPlural,
        noResults: _noResults,
        loadingLabel: _loadingLabel,
        loadMoreLabel: _loadMoreLabel,
    } = props
    const searchPlaceholder = _searchPlaceholder || "Search..."
    const searchLabel = _searchLabel || "Search"
    const results = _results || "Found {{count}} result for: {{term}}"
    const resultsPlural = _resultsPlural || "Found {{count}} results for: {{term}}"
    const noResults = _noResults || "No results found for: {{term}}"
    const loadingLabel = _loadingLabel || "Loading..."
    const loadMoreLabel = _loadMoreLabel || "Load more"

    const { searchTerm, setSearchTerm, inputRef, handleKeyDown, handleButtonClick } = useSearch({
        pageLanguage: language,
        autoFocus: false,
    })
    const [hitsPerPage, setHitsPerPage] = useState(12)
    const {
        results: searchResults,
        searchedTerm,
        loading,
        error,
    } = useSearchResults({ language, hitsPerPage: hitsPerPage })
    return (
        <div className={style.wrapper}>
            <div className={style.intro}>
                <div className={style.introContent}>
                    {title && <h1 className={style.title}>{title}</h1>}
                    <div className={style.searchBar}>
                        <input
                            ref={inputRef}
                            placeholder={searchPlaceholder}
                            className={style.searchInput}
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <button
                            type="button"
                            aria-label={searchLabel}
                            onClick={handleButtonClick}
                            className={style.searchButton}
                        >
                            <SearchIcon />
                        </button>
                        <div className={style.searchBarBorder}>&nbsp;</div>
                    </div>
                </div>
            </div>
            {searchedTerm && Array.isArray(searchResults?.hits) && !error && (
                <div className={style.results}>
                    <div className={style.resultsContent}>
                        {getResultsTitle({
                            searchTerm: searchedTerm,
                            resultsCount: searchResults.nbHits,
                            noResults,
                            results,
                            resultsPlural,
                            loading,
                            loadingLabel,
                            style,
                        })}
                        {searchResults.hits.length > 0 && (
                            <ul className={style.resultsList}>
                                {searchResults.hits.map(resultObj => {
                                    const kkey = `search_result_${resultObj.id.replace(/-/g, "_").replace(/\s+/g, "")}`
                                    return (
                                        <li key={kkey} className={style.resultsLi}>
                                            <Link href={resultObj.path}>
                                                <div className={style.resultWrapper}>
                                                    <h4 className={style.resultTitle}>{resultObj.title}</h4>
                                                    <p className={style.resultDescription}>{resultObj.description}</p>
                                                    <div className={style.resultImageGrid}>
                                                        {resultObj?.imagePath && (
                                                            <div className={style.resultImageWrapper}>
                                                                <img
                                                                    src={resultObj.imagePath}
                                                                    alt={"Result image"}
                                                                    className={style.resultImage}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        )}
                        {searchResults.hits.length < searchResults.nbHits && (
                            <div className={style.loadMoreWrapper}>
                                <LoadMoreButton
                                    label={loadMoreLabel}
                                    callback={() => setHitsPerPage(hitsPerPage + 8)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

SearchResultsContainer.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    searchLabel: PropTypes.string,
    results: PropTypes.string,
    resultsPlural: PropTypes.string,
    noResults: PropTypes.string,
    loadingLabel: PropTypes.string,
    language: PropTypes.oneOf(["nl-BE", "fr-BE", "nl-NL"]),
    loadMoreLabel: PropTypes.string,
}

export default SearchResultsContainer
