export const defaultStyle = {
    wrapper: "",
    richTextWrapper: "hidden",
    code: "",
    link: "",
    paragraph: "",
    heading1: "",
    heading2: "",
    heading3: "",
    heading4: "",
    heading5: "",
    heading6: "",
    table: "hidden",
    tRow: "hidden",
    tHeader: "hidden",
    tData: "hidden",
    ulList: "",
    list: "",
    title: "",
    mainLink: "",
}

export const COOKIES = {
    wrapper: "relative bg-white",
    richTextWrapper: "section-wrapper max-w-[1024px] text-left mt-[-10px] pt-[20px] py-[16px]",
    code: "",
    link: "",
    paragraph: "body-2 lighter pb-[24px]",
    heading1: "",
    heading2: "heading-2 pb-[24px]",
    heading3: "",
    heading4: "heading-4 pb-[24px]",
    heading6: "",
    table: "hidden",
    tRow: "hidden",
    tHeader: "hidden",
    tData: "hidden",
    ulList: "",
    list: "",
    title: "heading-2 pb-[64px]",
    mainLink: "",
}

export const ERROR = {
    wrapper: "",
    richTextWrapper:
        "bg-white relative flex flex-col items-center justify-center text-center w-full h-full mt-[-10px] pt-[90px] pb-[128px]",
    code: "",
    link: "",
    paragraph: "",
    heading1: "display-1 text-gold-500 pb-[64px]",
    heading2: "heading-2 pb-[64px]",
    heading3: "",
    heading4: "",
    heading5: "",
    heading6: "",
    table: "hidden",
    tRow: "hidden",
    tHeader: "hidden",
    tData: "hidden",
    ulList: "",
    list: "",
    title: "",
    mainLink: "btn-primary w-[168px] md:w-[215px] xxl:w-[400px] ",
}
