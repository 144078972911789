import React from "react"
import PropTypes from "prop-types"
import { CARD as style } from "./HistoryCarousel-tw-styles"
import ResponsiveImage from "../UI/ResponsiveImage/ResponsiveImage"
import Typography from "../UI/Typography/Typography"


const HistoryCard = props => {
  const { year, image, description } = props

  return (
    <div className={style.wrapper}>
      <div className={style.innerWrapper}>
        <ResponsiveImage className={style.image} contentfulImagesComponent={image}/>
        <p className={style.title}>{year}</p>
        <Typography className={style.description} content={description}/>
      </div>
    </div>
  )
}

HistoryCard.propTypes = {
  year: PropTypes.string,
  image: PropTypes.shape(),
  description: PropTypes.string,
}

export default HistoryCard
