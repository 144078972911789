import React from "react"
import PropTypes from "prop-types"
import * as styles from "./LinkCard-tw-styles"
import Link from "../Link/Link"
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage"

function LinkCard(props) {
    const { link, backgroundImage, foregroundImage, gridClasses, variant } = props
    const style = styles[variant] || styles.defaultStyle
    return (
        <Link {...link} className={`${style.wrapper} ${gridClasses ? gridClasses : ""}`}>
            <div className={style.imgWrapper}>
                <ResponsiveImage
                    isAnimated={backgroundImage.isAnimated}
                    contentfulImagesComponent={backgroundImage}
                    className={style.img}
                />
                {foregroundImage && (
                    <ResponsiveImage
                        isAnimated={foregroundImage.isAnimated}
                        contentfulImagesComponent={foregroundImage}
                        className={style.foregroundImage}
                    />
                )}
            </div>
            <div className={style.content}>
                <h3 className={style.link}>{link.label}</h3>
            </div>
        </Link>
    )
}

export default LinkCard

LinkCard.propTypes = {
    link: PropTypes.object,
    backgroundImage: PropTypes.object,
    foregroundImage: PropTypes.object,
    gridClasses: PropTypes.string,
    variant: PropTypes.oneOf([
        "TEXT_CENTERED",
        "INSTAGRAM_POST",
        "RECIPE_CARD",
        "TEXT_CENTERED_SHADOW_HOVER",
        "TEXT_CENTERED_SHADOW_HOVER_WEBP",
    ]),
}
